import React, { useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import IconArrowButton from "../icons/IconArrowButton";
import IconArrowButtonLeft from "../icons/IconArrowButtonLeft";

const stories = [
  {
    name: "Rob North",
    company: "Director, Dot Meet Dot Limited",
    content:
      "Adamo provided some initial designs which we fed back on and agreed before moving on to the development of the app. We communicated over Facebook Messenger. I was able to communicate directly with the developer who was working on the project, which really helped to ensure things got done quickly."
  },
  {
    name: "Dion",
    company: "Director",
    content:
      "I really liked the account setup process. During the demo, I sent my colleges an invite. While I was continuing my demonstration, he set up and configure his account. He felt it was smooth and clean. I also love the stability. The system operated without strange behavior, and the functionality worked smoothly."
  },
  {
    name: "Michel Truong",
    company: "CEO &amp; Founder",
    content:
      "I would like to take a few minutes in order to thank you all for the good job you did. It has been a pleasure to work with Adamo and to feel that you are driven by results. The results are satisfying!"
  },
  {
    name: "Paul Rowsthorn",
    company: "Airguides Co-founder and CEO",
    content:
      "Adamo digital completely redesigned and developed a tour operator&#039;s website. The team handled the complete frontend rebuild using HTML5 and CSS as well as backend upgrade with PHP storage."
  }
];
const Stories = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <div className="stories">
      <div className="stories__container">
        <div className="stories__heading">
          <h2>Our Success Stories</h2>
        </div>
        <div className="stories__content">
          <div className="stories__content__overview">
            <p>
              Adamo Software&#039;s glory starts with clients and ends with
              their satisfaction. We are fighting for your success!
            </p>
          </div>
          <div className="stories__content__share">
            <div className="stories__content__share__buttons">
              <a
                className="stories__content__share__button"
                ref={prevRef}
                title="Get a quote"
              >
                <IconArrowButtonLeft />
              </a>
              <a
                className="stories__content__share__button"
                ref={nextRef}
                title="Get a quote"
              >
                <IconArrowButton />
              </a>
            </div>
            <div className="stories__content__share__container-slides">
              <Swiper
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                loop={true}
                spaceBetween={0}
                navigation={true}
                modules={[Navigation]}
                grabCursor={true}
                className="stories__content__share__slides"
              >
                {stories.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="stories__content__share__card">
                      <p className="title">
                        <strong>{item.name}</strong>
                      </p>
                      <p className="company">{item.company}</p>
                      <div className="text">
                        <div className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            height={20}
                            width={20}
                          >
                            <path
                              fill="#4a4a51"
                              d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                            ></path>
                          </svg>
                        </div>
                        <p className="content">{item.content}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
