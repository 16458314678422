import { Button } from '@material-ui/core'
import { Box, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar, withSnackbar } from 'notistack'
import './styles.scss'

const useStyles = makeStyles(theme => ({
  root: {},
  cardContainer: {
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      width: 450
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: 450
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 450
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 400
    },
    [theme.breakpoints.down('xs')]: {
      width: 300
    }
  },
  emailForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.between('md', 'lg')]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },

  TextField: {
    height: theme.spacing(7),
    margin: theme.spacing(1.5, 0),

    [theme.breakpoints.up('lg')]: {
      width: 400,
      margin: theme.spacing(1.5, 0)
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: 400,
      margin: theme.spacing(1.5, 0)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 400,
      margin: theme.spacing(1.5, 0)
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 350,
      margin: theme.spacing(1, 0)
    },
    [theme.breakpoints.down('xs')]: {
      width: 260,
      margin: theme.spacing(1, 0)
    }
  },

  submitButton: {
    border: 'none',
    textAlign: 'center',
    height: theme.spacing(6),
    width: '60%',
    background: '#FF5F73',
    borderRadius: theme.spacing(3),
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginTop: theme.spacing(10),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#DC5060'
      // backgroundColor: '#F50057'
    }
  },
  // wrapper: {
  //   margin: theme.spacing(1),
  //   position: 'relative',
  // },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -14,
    marginLeft: -15
  }
}))

const FooterRight = props => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const checkIsFilledAllFields = elements => {
    if (
      elements.name.value &&
      elements.email.value &&
      elements.phone_number.value &&
      elements._subject.value &&
      elements.message.value
    ) {
      return true
    }
    return false
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    if (!checkIsFilledAllFields(e.target.elements)) {
      setLoading(false)
      enqueueSnackbar('Please fill full fields before clicking submit button.', {
        variant: 'warning'
      })
      return
    }

    const formData = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      phone_number: e.target.elements.phone_number.value,
      _subject: 'Tisoha - Subject - ' + e.target.elements._subject.value,
      message: e.target.elements.message.value,
      _template: 'table',
      _captcha: 'false',
      _honey: ''
    }

    fetch('https://formsubmit.co/bus.tisoha@gmail.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(data => {
        console.log(data)

        enqueueSnackbar('Send email success! We will contact you soon.', {
          variant: 'success'
        })
        e.target.reset()
        setLoading(false)
      })
      .catch(error => {
        enqueueSnackbar('Send email fail! Try again later.', {
          variant: 'error'
        })
        console.log(error)
        setLoading(false)
      })
  }
  return (
    <Box className={classes.root} id="contactForm">
      <Card className={classes.cardContainer}>
        <form className={classes.emailForm} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            className={classes.TextField}
            label="Name"
            variant="outlined"
            name="name"
            type="text"
            placeholder="Name"
            required
            // helperText="This field must not be empty."
          />
          <TextField
            className={classes.TextField}
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            placeholder="Email"
            required
            // helperText="This field must not be empty."
          />
          <TextField
            className={classes.TextField}
            label="Phone number "
            variant="outlined"
            name="phone_number"
            type="text"
            placeholder="Phone"
            required
            // helperText="This field must not be empty."
          />
          <TextField
            className={classes.TextField}
            label="Subject"
            variant="outlined"
            name="_subject"
            type="text"
            placeholder="Subject"
            required
            // helperText="This field must not be empty."
          />
          <TextField
            className={classes.TextField}
            label="Message"
            variant="outlined"
            name="message"
            type="text"
            placeholder="Message"
            multiline
            minRows={5}
            required
            // helperText="This field must not be empty."
          />

          <Button className={classes.submitButton} type="submit" disabled={loading}>
            Send Message
            {loading && (
              <CircularProgress size={28} thickness={5} className={classes.buttonProgress} />
            )}
          </Button>
        </form>
      </Card>
    </Box>
  )
}
export default withSnackbar(FooterRight)
