import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.up('md')]: {
      width: 180,
      height: 140
    },
    [theme.breakpoints.between('xs', 'md')]: {
      width: 140,
      height: 110
    },
    [theme.breakpoints.down('xs')]: {
      width: 120,
      height: 100
    }
  },
  number: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '64px',
    lineHeight: '72px',

    color: '#FF5F73',

    [theme.breakpoints.up('md')]: {
      width: 110,
      height: 75,
      fontSize: theme.spacing(8)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      width: 90,
      height: 65,
      fontSize: theme.spacing(6)
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 50,
      fontSize: theme.spacing(4)
    }
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    /* or 133% */

    letterSpacing: '0.02em',

    /* White */

    color: '#FFFFFF',

    [theme.breakpoints.up('md')]: {
      width: 175,
      height: 64,
      fontSize: theme.spacing(3),
      lineHeight: '32px'
    },
    [theme.breakpoints.between('xs', 'md')]: {
      width: 150,
      height: 64,
      fontSize: theme.spacing(2.5),
      lineHeight: '25px'
    },
    [theme.breakpoints.down('xs')]: {
      width: 120,
      height: 64,
      fontSize: theme.spacing(2),
      lineHeight: '20px'
    }
  }
}))
export default function InfoCard({ number, text }) {
  const classes = useStyles()

  return (
    <Box className={classes.card}>
      <Typography className={classes.number}>{number}</Typography>
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  )
}
InfoCard.propTypes = {
  number: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
