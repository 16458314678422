import * as React from 'react'

export default function IconGolang(props) {
  return (
    <svg
      width={84}
      height={31}
      viewBox="0 0 84 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.34 9.386c-.165 0-.205-.08-.124-.202l.859-1.09a.585.585 0 01.452-.202H22.13c.164 0 .204.121.123.243l-.696 1.05a.625.625 0 01-.41.242l-14.808-.04h.001zM.163 13.103c-.164 0-.204-.08-.122-.202l.858-1.09a.585.585 0 01.453-.202h18.646a.19.19 0 01.21.15.183.183 0 01-.006.093l-.326.969a.36.36 0 01-.368.243l-19.345.04zm9.898 3.717c-.164 0-.204-.121-.123-.242l.572-1.01a.54.54 0 01.41-.243H19.1c.164 0 .245.121.245.283l-.082.969a.301.301 0 01-.089.194.309.309 0 01-.197.089l-8.916-.04zm42.452-8.16l-6.872 1.777c-.614.162-.655.201-1.186-.404a5.42 5.42 0 00-1.922-1.535c-2.577-1.252-5.072-.89-7.403.606a8.638 8.638 0 00-3.143 3.277 8.506 8.506 0 00-1.028 4.399 6.288 6.288 0 001.55 4.2 6.435 6.435 0 003.971 2.143 7.636 7.636 0 003.833-.426 7.545 7.545 0 003.12-2.24c.369-.447.696-.93 1.104-1.495h-7.893c-.859 0-1.063-.525-.775-1.213.532-1.252 1.513-3.353 2.085-4.403.088-.195.232-.36.413-.474.181-.115.393-.175.608-.172h14.888c-.083 1.09-.083 2.181-.246 3.272a17.116 17.116 0 01-3.353 7.918 16.963 16.963 0 01-5.105 4.632 17.177 17.177 0 01-6.551 2.24 14.664 14.664 0 01-5.776-.228 14.57 14.57 0 01-5.227-2.44 12.737 12.737 0 01-3.564-3.939 12.574 12.574 0 01-1.63-5.032 15.67 15.67 0 01.527-6.266 15.79 15.79 0 012.95-5.571A18.181 18.181 0 0137.34.296c3.845-.687 7.525-.242 10.838 1.98a12.703 12.703 0 014.745 5.696c.246.364.082.565-.409.687z"
        fill="#111"
      />
      <path
        d="M66.052 31a15.475 15.475 0 01-9.98-3.555 12.63 12.63 0 01-4.416-7.797c-.736-4.565.532-8.606 3.313-12.2 2.986-3.88 6.584-5.9 11.45-6.747 4.173-.728 8.099-.323 11.657 2.06a12.65 12.65 0 013.898 3.87 12.474 12.474 0 011.869 5.139 15.412 15.412 0 01-.647 7.419 15.574 15.574 0 01-4.056 6.276 18.815 18.815 0 01-9.816 5.17c-1.104.202-2.208.242-3.271.364V31zm9.734-16.32a11.543 11.543 0 00-.123-1.334 6.607 6.607 0 00-1.003-2.563 6.696 6.696 0 00-1.967-1.943 6.817 6.817 0 00-5.373-.866 8.97 8.97 0 00-4.68 2.42 8.791 8.791 0 00-2.476 4.61 6.58 6.58 0 00.485 4.485 6.698 6.698 0 003.271 3.15 7.58 7.58 0 006.666-.244c3.231-1.652 4.99-4.241 5.195-7.716h.005z"
        fill="#111"
      />
    </svg>
  )
}
