import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import IconCircle from '../icons/IconCircle'
import IconColab from '../icons/IconColab'
import TypoTitle from '../Typography/TypoTitle'

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  svgContainer: {
    order: 2,
    flex: '1 1 400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  contentContainer: {
    order: 1,
    flex: '1 1 400px'
  },
  flexItemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    background: 'transparent',
    border: '1px solid #333333',
    borderRadius: '40px',

    [theme.breakpoints.up('md')]: {
      height: 400,
      width: 360
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(2),
      height: 360,
      width: 320
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      height: 300,
      width: 270
    }
  },
  detailText: {
    fontFamily: 'Roboto',
    fontWeight: 300,

    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: 'rgba(255, 255, 255, 0.8)',

    marginLeft: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}))
export default function WhyWorkWithUs() {
  const classes = useStyles()

  return (
    <Box className={classes.flexContainer}>
      <Box className={classes.svgContainer}>
        <Box className={classes.flexItemIcon}>
          <IconColab />
        </Box>
      </Box>

      <Box className={classes.contentContainer}>
        <Box className={classes.flexItem} marginBottom={4}>
          <p className={'typo--red'}>Why work with us</p>
          <TypoTitle
            color={'white'}
            fontSize={'3vw'}
            text={"You are here because we're doing our best for you."}
          />
        </Box>
        <Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Box height={16} width={16}>
              <IconCircle />
            </Box>
            <span className={classes.detailText}>Brilliant Team – Young, Smart, High Skill</span>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Box height={16} width={16}>
              <IconCircle />
            </Box>
            <span className={classes.detailText}>Creative & Profesional</span>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Box height={16} width={16}>
              <IconCircle />
            </Box>
            <span className={classes.detailText}>Complex Solutions</span>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Box height={16} width={16}>
              <IconCircle />
            </Box>
            <span className={classes.detailText}>100% Result Guarantee and Maintain</span>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Box height={16} width={16}>
              <IconCircle />
            </Box>
            <span className={classes.detailText}>Affordable Budget</span>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
