import React from 'react'

export default function IconDocker(props) {
  return (
    <svg
      width={78}
      height={54}
      viewBox="0 0 78 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.143 24.894h8.08v-7.277h-8.08v7.277zm-9.619 0h8.08v-7.277h-8.08v7.277zm-9.426 0h8.08v-7.277h-8.08v7.277zm-9.427 0h7.889v-7.277h-7.888v7.277zm-9.618 0h8.08v-7.277h-8.08v7.277zm9.618-8.809h7.889V8.808h-7.888v7.277zm9.427 0h8.08V8.808h-8.08v7.277zm9.426 0h8.08V8.808h-8.08v7.277zm0-8.808h8.08V0h-8.08v7.277zM78 21.638s-3.463-3.255-10.58-2.106c-.77-5.552-6.733-8.808-6.733-8.808s-5.578 6.702-1.542 14.172a12.95 12.95 0 01-5.962 1.338H.284C-.68 29.873-.68 54 25.867 54c19.044 0 33.28-8.809 40.013-24.894C75.884 29.873 78 21.638 78 21.638z"
        fill="#111"
      />
    </svg>
  )
}
