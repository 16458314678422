import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

export function ProjectFilter({ onFilterClick, filters, activeFilter }) {
  return (
    <div className="filter-container">
      {filters.map(filter => (
        <ButtonFilter
          key={filter.code}
          isActive={filter.code === activeFilter}
          onClick={() => onFilterClick(filter)}
        >
          {filter.name.toUpperCase()}
        </ButtonFilter>
      ))}
    </div>
  )
}

const ButtonFilter = ({ isActive, onClick, children }) => {
  return (
    <button className={`filter-button ${isActive && 'active'}`} onClick={onClick}>
      {children}
    </button>
  )
}

ProjectFilter.propTypes = {
  onFilterClick: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  activeFilter: PropTypes.string.isRequired
}
ButtonFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired
}
