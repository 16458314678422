import * as React from 'react'

function IconMobileApp(props) {
  return (
    <svg
      width={40}
      height={64}
      viewBox="0 0 40 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 9h4"
        stroke="#FF5F73"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.238 63.5H4.762c-1.111 0-2.19-.494-2.994-1.395C.96 61.202.5 59.965.5 58.667V5.333c0-1.299.461-2.535 1.268-3.438C2.572.994 3.65.5 4.762.5h30.476c1.111 0 2.19.494 2.994 1.395.807.903 1.268 2.14 1.268 3.438v53.334c0 1.299-.461 2.535-1.268 3.438-.804.901-1.883 1.395-2.994 1.395z"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 55a4 4 0 100-8 4 4 0 000 8z" fill="#FF5F73" />
    </svg>
  )
}

export default IconMobileApp
