import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import TypoTitle from '../Typography/TypoTitle'

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  contentItem: {
    flex: '0 1 500px',
    marginBottom: theme.spacing(5)
  },
  flexItemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '360px',
    height: '400px',
    background: 'transparent',
    border: '1px solid #333333',
    borderRadius: 40
  },
  detailText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '18px',
    letterSpacing: '0.02em',
    color: 'rgba(255, 255, 255, 0.8)',

    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      lineHeight: '28px'
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(-3),
      fontSize: 16,
      lineHeight: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(-2),
      fontSize: 14,
      lineHeight: '24px'
    }
  },
  svgContainer: {
    width: '2em'
  }
}))
export default function BusinessModels() {
  const classes = useStyles()

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box marginBottom={3}>
          <p className={'typo--red'}>BUSINESS MODELS</p>
        </Box>
      </Box>

      <Box className={classes.contentContainer}>
        <Box className={classes.contentItem}>
          <Box marginBottom={3}>
            <TypoTitle color="white" text="Extended Team" fontSize="3vw" />
          </Box>
          <Box>
            <Typography className={classes.detailText}>
              The Extended Team is a model where the client can control the entire development team
              remotely. It is cost-effective as the entire team is hired remotely and does not
              require the investment in the infrastructure still they have control and coordination
              with the team.The best part is the client hires them on contract only after all the
              due diligence and can relieve them upon completion of the project or modules.
            </Typography>
          </Box>
        </Box>

        <Box className={classes.contentItem}>
          <Box marginBottom={3}>
            <TypoTitle color="white" text="Time & Material" fontSize="3vw" />
          </Box>

          <Box>
            <Typography className={classes.detailText}>
              We welcome the obscure vision through Time and Material Model that supports the Agile
              Development Process. This is a model where the client only pays for the time and
              resources spent on the project.
            </Typography>
          </Box>
        </Box>

        <Box className={classes.contentItem}>
          <Box marginBottom={3}>
            <TypoTitle color="white" text="Fix Scope Model" fontSize="3vw" />
          </Box>

          <Box>
            <Typography className={classes.detailText}>
              We welcome the crystal clear vision of our clients and provide the best web and mobile
              app solutions through Fix Scope Model. Fix Scope Model is the best model to get the
              complete solution under a fixed budget.
            </Typography>
          </Box>
        </Box>
        {/* <Box flexBasis="48%" className={classes.svgContainer}>
               <IconLogoOutlined />
            </Box> */}
      </Box>
    </Box>
  )
}
