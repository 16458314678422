import * as React from 'react'

function IconFace(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16 8.048a8 8 0 10-9.25 7.903v-5.59H4.719V8.048H6.75V6.285c0-2.004 1.195-3.112 3.021-3.112.6.008 1.2.06 1.792.156v1.969h-1.01a1.156 1.156 0 00-1.303 1.25v1.5h2.217l-.355 2.313H9.245v5.59A8 8 0 0016 8.048z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconFace
