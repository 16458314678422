import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import IconPlatformWeb from '../icons/IconPlatformWeb'
import IconPlatformIphone from '../icons/IconPlatformIphone'
import IconPlatformAndroid from '../icons/IconPlatformAndroid'
import IconPlatformWearable from '../icons/IconPlatformWearable'
import IconPlatformIOT from '../icons/IconPlatformIOT'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(8, 0)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      margin: theme.spacing(5, 0)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0)
    }
  },
  item: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginRight: theme.spacing(5)
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(3)
    }
  },
  title: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(6, 0, 3)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      margin: theme.spacing(5, 0, 2.5)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0, 1.5)
    }
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px'
  }
}))
export default function Platforms() {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.title}
      >
        <p className={'typo--red'}>Platforms</p>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.item}
        >
          <Box marginBottom={2}>
            <IconPlatformWeb />
          </Box>
          <p className={classes.text}>Web</p>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          className={classes.item}
        >
          <Box marginBottom={2}>
            <IconPlatformIphone />
          </Box>
          <p className={classes.text}>IOS</p>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          className={classes.item}
        >
          <Box marginBottom={2}>
            <IconPlatformAndroid />
          </Box>
          <p className={classes.text}>Android</p>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          className={classes.item}
        >
          <Box marginBottom={2}>
            <IconPlatformWearable />
          </Box>
          <p className={classes.text}>Wearable</p>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          className={classes.item}
        >
          <Box marginBottom={2}>
            <IconPlatformIOT />
          </Box>
          <p className={classes.text}>IOT</p>
        </Box>
      </Box>
    </Box>
  )
}
