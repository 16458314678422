import React from 'react'

export default function IconPython(props) {
  return (
    <svg width={60} height={60} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <rect x={10} width={20} height={40} rx={10} ry={5} />
      <rect y={10} width={40} height={20} rx={5} ry={10} />
      <circle cx={14.5} cy={5} r={1.85} fill="#fff" />
      <circle cx={25.5} cy={35} r={1.85} fill="#fff" />
      <path stroke="#fff" d="M10 9.5L20 9.5" />
      <path stroke="#fff" d="M20 30.5L30 30.5" />
      <path d="M9.5 30c0-10 2.5-10 10-10 8.5 0 11 0 11-10" stroke="#fff" fill="none" />
    </svg>
  )
}
