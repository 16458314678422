import React from 'react'

export default function IconPlatformAndroid(props) {
  return (
    <svg
      width={64}
      height={128}
      viewBox="0 0 64 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M56 0H8a8 8 0 00-8 8v112a8 8 0 008 8h48a8 8 0 008-8V8a8 8 0 00-8-8z" fill="#fff" />
      <path
        d="M56 .5H8A7.5 7.5 0 00.5 8v112a7.5 7.5 0 007.5 7.5h48a7.5 7.5 0 007.5-7.5V8A7.5 7.5 0 0056 .5z"
        stroke="#000"
      />
      <path d="M56 4H8a8 8 0 00-8 8v104a8 8 0 008 8h48a8 8 0 008-8V12a8 8 0 00-8-8z" fill="#fff" />
      <path
        d="M56 4.5H8A7.5 7.5 0 00.5 12v104a7.5 7.5 0 007.5 7.5h48a7.5 7.5 0 007.5-7.5V12A7.5 7.5 0 0056 4.5z"
        stroke="#000"
      />
    </svg>
  )
}
