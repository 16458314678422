import * as React from 'react'

function IconUi_Ux(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M20 4H5a3 3 0 00-3 3v15a3 3 0 003 3h15a3 3 0 003-3V7a3 3 0 00-3-3z"
          stroke="#828282"
        />
        <path
          d="M43.395 1.872L32.87 12.396a2.976 2.976 0 000 4.209l10.524 10.524a2.976 2.976 0 004.208 0l10.524-10.524a2.976 2.976 0 000-4.209L47.603 1.872a2.976 2.976 0 00-4.208 0z"
          stroke="#FF5F73"
        />
        <path
          d="M53 37H38a3 3 0 00-3 3v15a3 3 0 003 3h15a3 3 0 003-3V40a3 3 0 00-3-3zM20 37H5a3 3 0 00-3 3v15a3 3 0 003 3h15a3 3 0 003-3V40a3 3 0 00-3-3z"
          stroke="#828282"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H60V60H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconUi_Ux
