import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  text: {
    color: props => (props.color ? props.color : 'black'),
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    // lineHeight:'10vw',
    lineHeight: props => (props.lineHeight === '10vw' ? '10vw' : 'none'),
    // textTransform: 'uppercase',
    '-webkit-text-stroke': props => (props.color === 'white' ? '1px black' : '1px white'),
    animation: props => (props.animated ? 'slideTypoOutlinedToTop ease 1s' : 'none'),

    // fontSize: props => props.fontSize ? props.fontSize : "11vw",
    [theme.breakpoints.up('md')]: {
      fontSize: '200px',
      lineHeight: 0.85
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '130px',
      lineHeight: 0.9
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '100px',
      lineHeight: 0.9
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
      lineHeight: 0.9
    }
  }
}))
export default function TypoOutlined(props) {
  const classes = useStyles(props)

  return (
    <>
      <p className={classes.text}>{props.text}</p>
    </>
  )
}

TypoOutlined.propTypes = {
  text: PropTypes.string.isRequired
}
