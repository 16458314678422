import * as React from 'react'

function IconWeb(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 16v43M1 16h58" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.5 11a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM19.5 11a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="#FF5F73"
      />
      <path
        d="M5.556.5h48.888A5.056 5.056 0 0159.5 5.556v48.888a5.056 5.056 0 01-5.056 5.056H5.556A5.056 5.056 0 01.5 54.444V5.556A5.056 5.056 0 015.556.5z"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconWeb
