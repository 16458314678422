import React from 'react'

export default function IconPlatformIOT(props) {
  return (
    <svg
      width={104}
      height={88}
      viewBox="0 0 104 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M100 56.5H4A3.5 3.5 0 00.5 60v24A3.5 3.5 0 004 87.5h96a3.5 3.5 0 003.5-3.5V60a3.5 3.5 0 00-3.5-3.5z"
        stroke="#000"
      />
      <path d="M88 79.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z" stroke="#000" />
      <path d="M88 76.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM16 0v56" stroke="#000" />
    </svg>
  )
}
