import { Box, Toolbar } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'

import { ScrollTop } from '../../components/BackToTop'
import DevelopmentMethodology from '../../components/DevelopmentMethodology'
import Footer from '../../components/Footer/Footer'
import GroupMobileApp from '../../components/groupmobileapp/GroupMobileApp'
import GroupProduct from '../../components/groupproducts/GroupProducts'
import GroupUiUx from '../../components/groupuiux/GroupUiUx'
import GroupWeb from '../../components/groupweb/GroupWeb'
import Platforms from '../../components/Platforms/index'
import SectionContainer from '../../components/SectionContainer'
import TypoOutlined from '../../components/Typography/TypoOutlined'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  heroTitle: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    }
  }
}))

export default function ServicePage(props) {
  const classes = useStyles()
  return (
    <Box marginTop={6} className={classes.root}>
      <Toolbar id="back-to-top-anchor" variant="dense" style={{ backgroundColor: 'white' }} />
      <SectionContainer backgroundColor="white">
        <Box className={classes.heroTitle}>
          <TypoOutlined text={'OUR SERVICES.'} color={'white'} fontSize={'11vw'} />
        </Box>

        <Box>
          <GroupMobileApp />
        </Box>
        <Box>
          <GroupWeb />
        </Box>
        <Box>
          <GroupUiUx />
        </Box>
        <Box>
          <GroupProduct />
        </Box>
      </SectionContainer>

      <SectionContainer backgroundColor="white">
        <Box marginTop={10}>
          <Platforms />
        </Box>{' '}
        <Box>
          <DevelopmentMethodology />
        </Box>
      </SectionContainer>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  )
}
