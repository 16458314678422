import React from 'react'

export default function IconReact(props) {
  return (
    <svg
      width={72}
      height={66}
      viewBox="0 0 72 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.001 39.425c3.494 0 6.327-2.903 6.327-6.486 0-3.582-2.833-6.486-6.327-6.486-3.494 0-6.327 2.904-6.327 6.486 0 3.583 2.833 6.486 6.327 6.486z"
        fill="#111"
      />
      <path
        clipRule="evenodd"
        d="M36.001 19.751c8.495 0 16.386 1.25 22.336 3.35 7.172 2.53 11.577 6.365 11.577 9.838 0 3.62-4.672 7.693-12.37 10.308-5.82 1.977-13.48 3.01-21.543 3.01-8.267 0-16.096-.966-21.982-3.031-7.447-2.61-11.932-6.737-11.932-10.287 0-3.444 4.208-7.25 11.276-9.777 5.973-2.134 14.059-3.41 22.637-3.41H36z"
        stroke="#111"
        strokeWidth={3}
      />
      <path
        clipRule="evenodd"
        d="M24.802 26.383c4.245-7.544 9.242-13.928 13.99-18.163C44.51 3.117 49.956 1.12 52.888 2.854c3.058 1.808 4.167 7.994 2.53 16.135-1.235 6.157-4.19 13.474-8.219 20.635-4.13 7.343-8.86 13.814-13.542 18.008-5.925 5.31-11.654 7.23-14.653 5.457-2.91-1.719-4.024-7.36-2.627-14.899 1.18-6.37 4.14-14.189 8.425-21.807h0z"
        stroke="#111"
        strokeWidth={3}
      />
      <path
        clipRule="evenodd"
        d="M24.813 39.643c-4.256-7.536-7.154-15.164-8.363-21.496-1.456-7.628-.427-13.46 2.505-15.199 3.055-1.814 8.838.29 14.9 5.81 4.585 4.173 9.293 10.454 13.333 17.606 4.142 7.335 7.244 14.767 8.455 21.02 1.528 7.915.29 13.962-2.706 15.74-2.908 1.727-8.23-.1-13.903-5.106-4.794-4.23-9.924-10.764-14.22-18.375h-.001z"
        stroke="#111"
        strokeWidth={3}
      />
    </svg>
  )
}
