import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { Typography } from '@material-ui/core'

export function ProjectGrid({ projects }) {
  return (
    <motion.div
      layout
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className="projects-grid"
    >
      {projects.map(project => (
        <ProjectCard key={`${project.slug}_${project.id}`} project={project} />
      ))}
    </motion.div>
  )
}

const ProjectCard = ({ project }) => {
  return (
    <motion.div layout className="project-card">
      <AnimatePresence >
        <img className="project-cover" alt={project.cover.alt} src={project.cover.relativeUrl} />
        <div className="project-card--overlay">
          <h2 className="project-card--title">
            <a href={'/portfolio/' + project.slug}>{project.title}</a>
          </h2>
          <p
            className="project-card--description"
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
        </div>
      </AnimatePresence>
    </motion.div>
  )
}

ProjectGrid.propTypes = {
  projects: PropTypes.array.isRequired
}
ProjectCard.propTypes = {
  project: PropTypes.object.isRequired
}
