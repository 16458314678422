import { Box } from '@material-ui/core'
import React from 'react'
import CustomDotsCarousel from '../CustomDotsCarousel'

export default function index() {
  return (
    <Box display="flex" justifyContent="center">
      <Box display="flex" flexWrap="wrap" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={4}
          marginLeft={1}
        >
          <p className={'typo--red'}>Testimonials</p>
        </Box>

        <CustomDotsCarousel />
      </Box>
    </Box>
  )
}
