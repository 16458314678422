import React from 'react'

export default function IconFirebase(props) {
  return (
    <svg
      width={50}
      height={70}
      viewBox="0 0 50 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.834 25.275l-6.931 6.516-6.43-13.113 3.326-7.537c.866-1.53 2.216-1.513 3.06 0l6.975 14.134z"
        fill="#696969"
      />
      <path d="M19.47 18.681l6.431 13.114L0 56.14l19.47-37.46z" fill="#434343" />
      <path
        d="M40.413 15.313c1.237-1.202 2.518-.791 2.845.912l6.743 39.592-22.35 13.558c-.918.341-1.88.552-2.855.626a9.61 9.61 0 01-2.612-.657L0 56.141l40.413-40.828z"
        fill="#111"
      />
      <path
        d="M19.473 18.683L.005 56.14l8.67-54.746c.32-1.706 1.281-1.87 2.139-.366l8.66 17.654z"
        fill="#696969"
      />
    </svg>
  )
}
