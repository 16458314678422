import { Box, makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import IconAndroid from '../icons/IconAndroid'
import IconDocker from '../icons/IconDocker'
import IconDotNet from '../icons/IconDotNet'
import IconFirebase from '../icons/IconFirebase'
import IconFlutter from '../icons/IconFlutter'
import IconGolang from '../icons/IconGolang'
import IconIOS from '../icons/IconIOS'
import IconNodeJs from '../icons/IconNodeJs'
import IconPython from '../icons/IconPython'
import IconReact from '../icons/IconReact'
import IconJava from '../icons/IconJava'
import IconAngular from '../icons/IconAngular'
import './styles.scss'

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(6, 0, 3)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      margin: theme.spacing(5, 0, 2.5)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0, 1.5)
    }
  },
  techCard: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(5),
      width: '120px',
      height: '100px'
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginRight: theme.spacing(3),
      width: '100px',
      height: '85px'
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1.5),
      width: '100px',
      height: '80px'
    }
  },
  cardName: {
    marginTop: theme.spacing(1),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px'
  }
}))

export default function TrendingTechs() {
  const classes = useStyles()
  const cards = [
    {
      key: 'flutter',
      name: 'Flutter',
      icon: <IconFlutter />
    },
    {
      key: 'golang',
      name: 'Golang',
      icon: <IconGolang />
    },
    {
      key: 'reactjs',
      name: 'ReactJS',
      icon: <IconReact />
    },
    {
      key: 'nodejs',
      name: 'NodeJS',
      icon: <IconNodeJs />
    },
    {
      key: 'firebase',
      name: 'Firebase',
      icon: <IconFirebase />
    },
    {
      key: 'docker',
      name: 'Docker',
      icon: <IconDocker />
    },
    {
      key: 'python',
      name: 'Python',
      icon: <IconPython />
    },
    {
      key: 'ios',
      name: 'iOS',
      icon: <IconIOS />
    },
    {
      key: 'android',
      name: 'Android',
      icon: <IconAndroid />
    },
    {
      key: 'dotNet',
      name: '.NET',
      icon: (
        <img
          src={require('../../assets/images/dotnet.png').default}
          width={80}
          height={80}
          className="img-black"
        />
      )
    },
    {
      key: 'java',
      name: 'Java',
      icon: (
        <img
          src={require('../../assets/images/java.png').default}
          width={48}
          height={65}
          className="img-black"
        />
      )
    },
    {
      key: 'angular',
      name: 'Angular/AngularJS',
      icon: (
        <img
          src={require('../../assets/images/angular.png').default}
          width={56}
          height={60}
          className="img-black"
        />
      )
    }
  ]
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.title}
      >
        <p className={'typo--red'}>Techs</p>
      </Box>
      <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
        {cards.map(card => {
          return (
            <Box key={card.key} marginBottom={2}>
              <Paper elevation={0} className={classes.techCard}>
                {card.icon}
              </Paper>
              <p className={classes.cardName}>{card.name}</p>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
