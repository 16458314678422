import React from "react";
import Projects from "../../components/Projects/Projects";

import IdeaBlock from "../../components/IdeaBlock/IdeaBlock";
import Stories from "../../components/Stories/Stories";

import Banner from "../../components/Banner";
import Power from "../../components/Power";
import WhyClientLove from "../../components/WhyClientLove";
import OurWorks from "../../components/OurWorks";

const OurClient = () => {
  return (
    <>
      <Banner></Banner>
      <Power></Power>
      <WhyClientLove></WhyClientLove>
      <OurWorks></OurWorks>
      <Projects />
      <IdeaBlock />
      <Stories />
    </>
  );
};

export default OurClient;
