import React from 'react'

const WhyClientLove = () => {
  return (
    <div className='why-client-love'>
      <div className='container'>
        <div className='row why-client-love-content'>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <h2>Why clients love our works</h2>
            <p className='mb-0 overview'>Tisoha Software delivers cutting-edge custom software development outsourcing solutions. Your satisfaction is the most valuable achievement for us!</p>
          </div>
          <div className='image'>
            <img alt='' src={require('../../assets/image/why-client-love.png').default} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default WhyClientLove
