import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from 'antd'
import React from 'react'
import IconCircle from '../icons/IconCircle'
import IconWeb from '../icons/IconWeb'
import TypoTitle from '../Typography/TypoTitle'

const useStyles = makeStyles(theme => ({
  titlename: {
    position: 'static',
    width: '3vw',
    height: '10%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '42px',
    color: 'white',
    '-webkit-text-stroke': '1px black',
    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 40
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  BoxServices: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  spanCircle: { textAlign: 'justify' }
}))
export default function GroupWeb(props) {
  const classes = useStyles(props)

  return (
    <Box flexWrap="wrap" marginTop={10} className={classes.BoxServices}>
      <Box alignItems="center" flexDirection="row" width={'250px'}>
        <Card cover={<IconWeb />}>
          <TypoTitle text={'Web'} fontSize={'3vw'} />
          <Box className={classes.titlename}>
            <span>Development</span>
          </Box>
        </Card>
      </Box>

      <Box width={'500px'}>
        <Box className={classes.spanCircle} alignItems="center">
          <span>
            Our web application development service provides custom web application development from
            a simple content web site to large-scale web based Internet applications, electronic
            businesses and social network services. With its vast experience of different web
            technologies, TISOHA is your best development partner helping you with development and
            delivery of your ideas to the market on time. By choosing TISOHA as your development
            partner you will have no limitations – your app will be developed utilizing your
            favorite web technologies:
          </span>
          <br />
          <Box display="flex">
            <Box marginRight={5}>
              <Box display="flex" alignItems="center">
                <Box width={15} height={15} marginRight={1}>
                  <IconCircle />
                </Box>
                <span>ReactJS</span>
              </Box>

              <Box display="flex" alignItems="center">
                <Box width={15} height={15} marginRight={1}>
                  <IconCircle />
                </Box>
                <span>PHP</span>
              </Box>
            </Box>

            <Box>
              <Box display="flex" alignItems="center">
                <Box width={15} height={15} marginRight={1}>
                  <IconCircle />
                </Box>
                <span>Angular JS</span>
              </Box>

              <Box display="flex" alignItems="center">
                <Box width={15} height={15} marginRight={1}>
                  <IconCircle />
                </Box>
                <span>.NET</span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
