import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  text: {
    color: props => (props.color ? props.color : 'black'),
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    animation: props => (props.animated ? 'slideTypoNormalToTop ease 1s' : 'none'),
    lineHeight: 1.1,

    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 40
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }

    // fontSize: props => props.fontSize ? props.fontSize : "4vw",
  }
}))
export default function TypoTitle(props) {
  const classes = useStyles(props)

  return (
    <>
      <p className={classes.text}>{props.text}</p>
    </>
  )
}

TypoTitle.propTypes = {
  text: PropTypes.string.isRequired
}
