import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import React from 'react'

import IconFace from '../icons/IconFace'
import IconInstagram from '../icons/IconInstagram'
import IconLinked from '../icons/IconLinkedin'
import IconLocate from '../icons/IconLocate'
import IconMessenger from '../icons/IconMessenger'
import IconPhone from '../icons/IconPhone'

import '../../scss/styles.scss'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '24px',

    marginRight: theme.spacing(2),
    ':not(:last-child)': {
      margin: 'none'
    }
  },
  p: {
    color: 'black',
    opacity: '0.8',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '12px',
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  h: {
    // width: '139px',
    height: '24px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.3em',
    textTransform: 'uppercase',
    color: '#FF5F73'
  },
  a: {
    color: 'black',
    opacity: '0.8',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    marginLeft: '12px',
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  Box: {
    marginTop: '60px'
  },
  license: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#4F4F4F'
  },
  textFillForm: {
    fontSize: '18px',
    color: 'white'
  },
  linkPrivacy: {
    color: '#4F4F4F',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  textOuntlined: {
    color: 'black',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '-webkit-text-stroke': '1px white',

    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 30
    }
  }
}))

export default function FooterRight(props) {
  const classes = useStyles()
  const location = useLocation()

  return (
    <Box className={classes.root}>
      <Box>
        <p className={classes.textOuntlined}>CONTACT US</p>
        {location.pathname === '/' && (
          <p className={classes.textFillForm}>
            Fill the form beside to say &apos;Hello!&apos;{' '}
            <span style={{ color: '#2DAA67' }}>➔</span>
          </p>
        )}
      </Box>
      <Box className={classes.Box}>
        <h5 className={classes.h}>GET IN TOUCH</h5>
        <Box display="flex" alignItems="center" marginTop={1}>
          <IconMessenger />
          <a href="mailto:info@tisoha.com" className={classes.p}>
            info@tisoha.com
          </a>
        </Box>

        <Box display="flex" alignItems="center" marginTop={1}>
          <IconPhone />
          <a href="tel:0868222040" className={classes.p}>
            (+84) 086 822 2040
          </a>
        </Box>

        <Box display="flex" alignItems="center" marginTop={1}>
          <IconLocate />
          <a href="https://goo.gl/maps/oC7vk7NqF9mRRquz8" className={classes.p}>
            144 Nguyen Trai, Ben Thanh ward, District 1, HCM City
          </a>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <IconLocate />
          <a href="https://goo.gl/maps/xHte9pd2vJcoWxN18" className={classes.p}>
            D21402 4S Linh Dong Building, Street 30, Linh Dong Ward, Thu Duc, HCM City
          </a>
        </Box>
      </Box>

      <Box className={classes.Box}>
        <h5 className={classes.h}>FOLLOW US ON</h5>
        <Box display="flex" alignItems="center" marginTop={1}>
          <IconFace />
          <a href="https://www.facebook.com/tisoha.software.solutions" className={classes.a}>
            Facebook
          </a>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <IconLinked />
          <a
            href="https://www.linkedin.com/company/tisoha-software-solutions/"
            className={classes.a}
          >
            LinkedIn
          </a>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <IconInstagram />
          <a href="https://www.instagram.com/tisoha.development/" className={classes.a}>
            Instagram
          </a>
        </Box>
      </Box>
    </Box>
  )
}
