import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import IconArrowButton from "../icons/IconArrowButton";

const productImages = [
  {
    imgURl:
      "https://adamosoft.com/storage/images/adamo-customer1651248628ykcfw4.png"
  },
  {
    imgURl:
      "https://adamosoft.com/storage/images/adamo-customer1651248611J0Wi0b.png"
  },
  {
    imgURl:
      "https://adamosoft.com/storage/images/adamo-customer1651248557BBP1Tr.png"
  },
  {
    imgURl:
      "https://adamosoft.com/storage/images/adamo-customer1651248572rfEkAB.png"
  },
  {
    imgURl:
      "https://adamosoft.com/storage/images/adamo-customer1651248585hjXEzb.png"
  },
  {
    imgURl:
      "https://adamosoft.com/storage/images/adamo-customer16512485989Us9xN.png"
  }
];
const Projects = () => {
  return (
    <div className="projects">
      <div className="projects__container">
        <div className="projects__warpper">
          <h3 className="projects__sub-heading" data-wow-delay="0.1s">
            We take on challenging projects
          </h3>
          <p className="projects__overview" data-wow-delay="0.2s">
            While every Adamo client is unique, all share the need for products
            that build and grow revenue streams, enhance communications and
            streamline operations.
          </p>
          <a
            href="#"
            className="projects__button"
            data-wow-delay="0.3s"
            title="content.page.discuss_btn"
          >
            Let’s discuss your next project
            <IconArrowButton />
          </a>
        </div>
      </div>
      <div className="projects__about-us">
        <Swiper
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          autoplay={{
            delay: "1000"
          }}
          modules={[Autoplay]}
          onSwiper={(swiper) => console.log(swiper)}
          className="projects__about-us__wrapper"
        >
          {productImages.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item.imgURl}
                alt="product images"
                className="projects__about-us__slide"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Projects;
