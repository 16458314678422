import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.backgroundColor ?? '',
    display: 'flex',
    width: '100%'
  },
  content: {
    width: '70%',
    margin: '0 auto'
  }
}))
export default function SectionContainer(props) {
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.content}>{props.children}</div>
    </div>
  )
}

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired
}
