import logo from '../../assets/images/tisoha_logo.png'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll'

import PrimaryButton from '../buttons/PrimaryButton'
import '../../scss/styles.scss'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Roboto',
    flexGrow: 1
  },
  appbar: {
    background: 'black',
    padding: theme.spacing(1, 0)
  },
  appbarWrapper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 8)
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 6)
    },
    [theme.breakpoints.up('xs')]: {
      margin: theme.spacing(0, 2)
    }
  },
  title: {
    flexGrow: 1,
    svg: {
      height: '100%',
      width: '40px'
    }
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2)
    },
    marginLeft: theme.spacing(0)
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300',
    fontSize: '10px',
    lineHeight: '24px',

    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      margin: theme.spacing(0, 1)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '14px',
      margin: theme.spacing(0, 1)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      margin: theme.spacing(0, 0.7)
    },

    ':not(:last-child)': {
      margin: 'none'
    }
  }
}))

export default function Header() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar className={classes.appbarWrapper} variant="dense">
          <Typography className={classes.title}>
            <NavLink className={classes.logo} to="/">
              <img alt="" src={logo} />
            </NavLink>
          </Typography>

          <NavLink className={classes.link} exact to="/">
            HOME
          </NavLink>
          <NavLink className={classes.link} exact to="/services">
            SERVICES
          </NavLink>
          <NavLink className={classes.link} exact to="/portfolio">
            PORTFOLIO
          </NavLink>
          <Link
            className={classes.link}
            to="contactForm"
            smooth={true}
            duration={2000}
            offset={-160}
          >
            <PrimaryButton text={'contact us'} />
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  )
}
