/* eslint-disable react/prop-types */
import { CssBaseline } from '@material-ui/core'
import { Route, Switch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

import Homepage from './pages/Homepage'
import ServicePage from './pages/Services/Services'
import OurClient from './pages/OurClient/OurClient'
import PortfolioPage from './pages/Portfolio'
import ProjectDetailPage from './pages/ProjectDetails'

import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'
import NotFound from './pages/NotFound'

const styles = {
  success: { backgroundColor: '#2DAA67' },
  error: { backgroundColor: '#F50057' }
}

function App({ classes }) {
  return (
    <div className="App">
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        preventDuplicate
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error
        }}
      >
        <CssBaseline />
        <Header />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/services" component={ServicePage} />
          <Route path="/portfolio" exact component={PortfolioPage} />
          <Route path="/portfolio/:projectSlug" component={ProjectDetailPage} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/our-client" component={OurClient} />
          <Route component={NotFound} />
        </Switch>
      </SnackbarProvider>
    </div>
  )
}

export default withStyles(styles)(App)
