/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import Footer from '../../components/Footer/Footer'
import SectionContainer from '../../components/SectionContainer'
import projects from '../../data/projects'
import './styles.scss'

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

function ProjectDetailsPage() {
  const history = useHistory()
  const { projectSlug } = useParams()
  const [project, setProject] = useState(null)

  useEffect(() => {
    if (!projectSlug) {
      console.log('You must specify a project slug')
      history.push('portfolio')
      return
    }

    const _project = projects.find(project => project.slug === projectSlug)

    if (!_project) {
      console.log('invalid slug')
      history.push('/portfolio')
      return
    }

    setProject(_project)
  }, [])

  if (!project) {
    return <></>
  }

  return (
    <div className="project-detail-container">
      <div className="project-banner" style={{ backgroundImage: `url(${project.banner})` }}>
        <div className="project-desc">
          <h1 className="project-title">{project.title}</h1>
          <div
            className="project-short-desc"
            dangerouslySetInnerHTML={{ __html: project.shortDescription }}
          />
        </div>
      </div>

      <SectionContainer backgroundColor="white">
        <div className="project-details">
          <div
            className="project-short-desc"
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
        </div>
        <div className="project-info">
          <div className="project-background">
            <h3>Technological Stack</h3>
            <div dangerouslySetInnerHTML={{ __html: project.stack }}></div>
            <h3 style={{ marginTop: 10 }}>Market</h3>
            <div dangerouslySetInnerHTML={{ __html: project.market }}></div>
            {project.links.length > 0 && <h3 style={{ marginTop: 10 }}>Links</h3>}
            <ul>
              {project.links.map(link => (
                <li key={`${link.title}_${link.url}`}>
                  <a href={link.url}>{link.title}</a>
                </li>
              ))}
            </ul>
            {/* <div dangerouslySetInnerHTML={{ __html: project.market }}></div> */}
          </div>
          <div className="what-we-did">
            <h3>Propose Solutions </h3>
            <div dangerouslySetInnerHTML={{ __html: project.proposeSolutions }}></div>
          </div>
        </div>
      </SectionContainer>

      {/* <div
        className="project-banner"
        style={{ backgroundImage: `url(${project.imagesUrl})` }}
      ></div>

      <SectionContainer backgroundColor="white">
        <div className="project-results">
          <h3>Result</h3>
          <p>{project.result}</p>
        </div>
      </SectionContainer> */}

      <SectionContainer backgroundColor="#F9FAFB">
        <div className="another-projects">
          <h3>You Might Also Like</h3>
          <div className="project-cards">
            {projects &&
              projects
                .slice(getRandomInt(projects.length - 2))
                .slice(0, 2)
                .map(project => (
                  <div key={project.id} className="another-project-card">
                    <div className="another-project-card__image">
                      <a href={'/portfolio/' + project.slug}>
                        <img id="image-vertical" src={project.cover.relativeUrl} />
                      </a>
                    </div>
                    <div className="another-project-card__content">
                      <h3 className="another-project-card__title">{project.title}</h3>
                      <p
                        className="another-project-card__desc"
                        dangerouslySetInnerHTML={{
                          __html:
                            project.description.length > 250
                              ? `${project.description.slice(0, 250)}...`
                              : project.description
                        }}
                      />
                      <a href={'/portfolio/' + project.slug}>Load more...</a>
                    </div>
                  </div>
                ))}
          </div>
          <PrimaryButton
            onClick={() => {
              history.push('/portfolio')
            }}
            text={'MORE SUCCESS STORIES'}
          />
        </div>
      </SectionContainer>
      <Footer />
    </div>
  )
}

export default ProjectDetailsPage
