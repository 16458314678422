import React from 'react'

const Power = () => {
  return (
    <div className='power'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-5'>
            <h2>Vietnam Leading Outsourcing Software Development Company</h2>
            <div className='image d-none d-lg-block'>
              <img alt='' src={require('../../assets/image/dot-grid.png').default} />
            </div>
          </div>
          <div className='col-12 col-lg-5 align-self-center'>
            <div className='overview'>Having profound expertise in over 50 offshore outsourcing software development projects, we are running a quality-driven model.  It applies innovative technical expertise to offer innovative, timely, and valuable solutions. Adamo plays a customer-centered business, which allows us to protect the core value of clients.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Power
