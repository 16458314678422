import React from 'react'

const Banner = () => {
  return (
    <>
      <div className='banner'>
        <div className='container'>
          <div className='row banner-content justify-content-center'>
            <div className='col-12 col-lg-5 align-self-center'>
              <h1>Our clients</h1>
            </div>
            <div className='col-12 col-lg-5'>
              <p className='mb-0 overview'>At Tisoha, we understand our clients&apos; needs. Our goal is to provide a custom software development solution that tailors for your business. As the top of software outsourcing company in Vietnam, we leverage comprehensive experiences in mobile apps and web development</p>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-10 image-full'>
              <img alt='' src={require('../../assets/image/our-clients.jpg').default} />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Banner
