import React from 'react'

export default function IconPlatformIphone(props) {
  return (
    <svg
      width={60}
      height={120}
      viewBox="0 0 60 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.333 3.75c0-.296.25-.536.556-.536h32.778v.536c0 3.255-2.736 5.893-6.111 5.893H19.444c-3.375 0-6.11-2.638-6.11-5.893zm1.142.536c.276 2.41 2.396 4.286 4.97 4.286h21.11c2.574 0 4.694-1.876 4.97-4.286h-31.05z"
        fill="#000"
      />
      <rect
        x={0.5}
        y={0.5}
        width={59}
        height={119}
        rx={7.5}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={3.83334}
        y={3.71436}
        width={52.3333}
        height={112.571}
        rx={5.5}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
