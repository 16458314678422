import React from 'react'

const OurWorks = () => {
  return (
    <div className='our-works'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10'>
            <div className='list'>
              <div className='item'>
                <div className='icon'>
                  <img alt='' src={require('../../assets/image/icon1.png').default} />
                </div>
                <h3>Respect client&apos;s budget and schedule</h3>
                <p className='mb-0 overview'>Serving multi-business types, from startups to corporates, our team has considerable industry insights and client capacity to guide them with a best-fitting solution in a short execution time. Accessing Agile and reliable approach, we define clearly project into spins, respecting Agile and Scrum, Tisoha Software permiss to strict follow schedules.</p>
              </div>
              <div className='item'>
                <div className='icon'>
                  <img alt='' src={require('../../assets/image/icon1.png').default} />
                </div>
                <h3>Respect client&apos;s budget and schedule</h3>
                <p className='mb-0 overview'>Serving multi-business types, from startups to corporates, our team has considerable industry insights and client capacity to guide them with a best-fitting solution in a short execution time. Accessing Agile and reliable approach, we define clearly project into spins, respecting Agile and Scrum, Tisoha Software permiss to strict follow schedules.</p>
              </div>
              <div className='item'>
                <div className='icon'>
                  <img alt='' src={require('../../assets/image/icon1.png').default} />
                </div>
                <h3>Respect client&apos;s budget and schedule</h3>
                <p className='mb-0 overview'>Serving multi-business types, from startups to corporates, our team has considerable industry insights and client capacity to guide them with a best-fitting solution in a short execution time. Accessing Agile and reliable approach, we define clearly project into spins, respecting Agile and Scrum, Tisoha Software permiss to strict follow schedules.</p>
              </div>                  
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurWorks
