import React from 'react'

export default function IconFlutter(props) {
  return (
    <svg
      width={52}
      height={64}
      viewBox="0 0 52 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M52 29.532L34.667 46.767 52 64H32.194l-17.34-17.233 17.34-17.235H52zM32.194 0L0 31.997l9.907 9.846L52 0H32.194z"
          fill="#111"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H52V64H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
