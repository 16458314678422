import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from 'antd'
import React from 'react'
import IconCircle from '../icons/IconCircle'
import IconUi_Ux from '../icons/IconUi_Ux'
import TypoTitle from '../Typography/TypoTitle'

const useStyles = makeStyles(theme => ({
  titlename: {
    position: 'static',
    width: '3vw',
    height: '10%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '42px',
    color: 'white',
    '-webkit-text-stroke': '1px black',
    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 40
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  BoxServices: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  spanCircle: { textAlign: 'justify' }
}))
export default function GroupUiUx(props) {
  const classes = useStyles(props)

  return (
    <Box marginTop={10} className={classes.BoxServices}>
      <Box alignItems="center" flexDirection="row" width={'250px'}>
        <Card hoverable cover={<IconUi_Ux />}>
          <TypoTitle text={'UI/UX'} fontSize={'3vw'} />
          <Box className={classes.titlename}>
            <span>Design</span>
          </Box>
        </Card>
      </Box>

      <Box width={'500px'}>
        <Box className={classes.spanCircle} alignItems="center">
          <span>
            We provide UI | UX design services that work and solve the real problems through
            interesting products and user experience. With the principle of ease of use and fun when
            interacting are active design, the design team always cares about rationality and
            simplicity that are the core in all our work in UI | UX.
          </span>
          <br />
          <Box display="flex" alignItems="center">
            <Box width={15} height={15} marginRight={1}>
              <IconCircle />
            </Box>
            <span className={classes.spanCircle}>Branding, Logo</span>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={15} height={15} marginRight={1}>
              <IconCircle />
            </Box>
            <span className={classes.spanCircle}> Mobile, Web</span>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
