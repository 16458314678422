import * as React from 'react'

function IconLocate(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M8 9a2 2 0 100-4 2 2 0 000 4z" fill="#BDBDBD" />
        <path
          d="M3.1 11.9l.502-.557-.007-.006-.007-.006C2.383 10.297 1.75 8.72 1.75 7A6.207 6.207 0 018 .75 6.207 6.207 0 0114.25 7c0 1.713-.628 3.304-1.857 4.447l-.007.007-.035.03-.13.118-.466.421-1.42 1.283c-.908.82-1.86 1.681-2.107 1.91a.32.32 0 01-.398-.046 349.68 349.68 0 00-2.165-1.964l-1.42-1.283-.466-.42-.13-.118-.035-.031-.009-.008-.002-.002h0L3.1 11.9z"
          stroke="#BDBDBD"
          strokeWidth={1.5}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconLocate
