import { Box } from '@material-ui/core'
import React from 'react'
import PrimaryButton from '../buttons/PrimaryButton'
import InfoCard from '../Cards/InfoCard'
import TypoTitle from '../Typography/TypoTitle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(8)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3)
    }
  },
  titleContent: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(1, 0)
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: theme.spacing(1, 0)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5, 0)
    }
  },
  readMoreButton: {
    margin: theme.spacing(1, 0)
  },
  cardsContainer: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(3, 0)
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: theme.spacing(3, 0)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0)
    }
  }
}))
export default function WhoWeAre() {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box>
            <p className={'typo--red'}>who we are</p>
          </Box>
          <Box className={classes.titleContent}>
            <TypoTitle
              color={'white'}
              fontSize={'3.3vw'}
              text={'We Are Committed To Our Customers Success From Start To Finish'}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" marginBottom={3}></Box>
      </Box>

      <Box className={classes.readMoreButton}>
        <PrimaryButton text={'read more+'} />
      </Box>

      <Box
        className={classes.cardsContainer}
        display="flex"
        flexWrap="wrap"
        justifyContent="start"
        alignItems="center"
      >
        {/* <InfoCard number="10+" text="Year Experience" /> */}
        <InfoCard number="20+" text="Happy Customer" />
        <InfoCard number="100%" text="Job Satisfaction" />
      </Box>
    </Box>
  )
}
