import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from 'antd'
import React from 'react'
import IconCircle from '../icons/IconCircle'
import IconGraphic from '../icons/IconGraphic'
import TypoTitle from '../Typography/TypoTitle'

const useStyles = makeStyles(theme => ({
  titlename: {
    position: 'static',
    width: '3vw',
    height: '10%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '42px',
    color: 'white',
    '-webkit-text-stroke': '1px black',
    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 40
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  BoxServices: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  spanCircle: { textAlign: 'justify' }
}))
export default function GroupProduct(props) {
  const classes = useStyles(props)

  return (
    <Box marginTop={10} className={classes.BoxServices}>
      <Box alignItems="center" flexDirection="row" width={'250px'}>
        <Card hoverable cover={<IconGraphic />}>
          <TypoTitle text={'Product'} fontSize={'3vw'} />
          <Box className={classes.titlename}>
            <span>Prototyping</span>
          </Box>
        </Card>
      </Box>
      <Box width={'500px'}>
        <Box className={classes.spanCircle} alignItems="center">
          <span>
            Product innovation is very high risk. Our clients can reduce risks by using our Product
            Prototyping service to reduce the development cost and time to market. We help our
            clients from conceptualization to rapid product prototyping. Our development process is
            built on strong collaboration. From initial consultations to ongoing maintenance, we’re
            with you every step of the way.
          </span>
          <br />
          <Box display="flex" alignItems="center">
            <Box width={15} height={15} marginRight={1}>
              <IconCircle />
            </Box>
            <span>Product Conceptualization</span>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={15} height={15} marginRight={1}>
              <IconCircle />
            </Box>
            <span> Rapid Prototyping</span>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={15} height={15} marginRight={1}>
              <IconCircle />
            </Box>
            <span>Minimum Viable Product (MVP) development</span>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
