import React from 'react'

export default function IconPlatformWearable(props) {
  return (
    <svg
      width={77}
      height={126}
      viewBox="0 0 77 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.154 14.232c.77-9.076 2.295-12.564 8.465-12.922 6.17-.358 11.766-.308 11.766-.308s5.596-.05 11.765.308c6.17.358 7.696 3.846 8.466 12.922.264 3.113.853 5.339 1.64 7.076H14.514c.787-1.737 1.377-3.963 1.64-7.076zM14.047 103.76c1.02 1.862 1.793 4.309 2.107 8.008.77 9.076 2.295 12.564 8.465 12.922 6.17.358 11.766.308 11.766.308s5.596.05 11.765-.308c6.17-.358 7.696-3.846 8.466-12.922.313-3.694 1.084-6.139 2.102-8l-.18.001h-44c-.164 0-.328-.003-.49-.009z"
        stroke="#000"
        strokeLinejoin="round"
      />
      <path
        d="M1 34.846C1 27.37 7.061 21.308 14.54 21.308h44c7.477 0 13.538 6.061 13.538 13.538v5.061c.194-.097.42-.138.71-.138h1.253c.89 0 1.45.345 1.61 1.557.16 1.213.16 10.443 0 11.656-.16 1.212-.72 1.557-1.61 1.557h-1.253a1.07 1.07 0 01-.71-.246v35.938c0 7.477-6.061 13.538-13.538 13.538h-44C7.06 103.769 1 97.708 1 90.231V34.846z"
        stroke="#000"
        strokeLinejoin="round"
      />
      <rect x={3.46158} y={24.0769} width={65.8462} height={77.2308} rx={11.0769} stroke="#000" />
    </svg>
  )
}
