import React from "react";

const IconArrowButtonLeft = (props) => {
  return (
    <svg width={25} height={25} viewBox="0 0 52 52">
      <path
        d="M24 18l-8 8m0 0h22m-22 0l8 8"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconArrowButtonLeft;
