import * as React from 'react'

export default function IconMessenger(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#BDBDBD" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
        <path d="M11.935 5.9L8.973 8.31a1.531 1.531 0 01-1.907 0L4.08 5.9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.273 14c2.027.006 3.394-1.66 3.394-3.708V5.713C14.667 3.666 13.3 2 11.273 2H4.727C2.7 2 1.333 3.666 1.333 5.713v4.58c0 2.047 1.367 3.713 3.394 3.707h6.546z"
        />
      </g>
    </svg>
  )
}
