import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import '../../scss/styles.scss'
import FooterRight from './FooterRight'
import FooterLeft from './FooterLeft'
import SectionContainer from '../SectionContainer'
import { NavLink, useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    padding: 30
  },
  footerRight: {
    flex: '1 1 30%',
    // order: 1,

    [theme.breakpoints.up('md')]: {
      order: 1
    },
    [theme.breakpoints.down('md')]: {
      order: 2
    }
  },
  footerLeft: {
    // order: 2,

    [theme.breakpoints.up('md')]: {
      order: 2
    },
    [theme.breakpoints.down('md')]: {
      order: 1
    }
  },
  license: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#4F4F4F'
  },
  linkPrivacy: {
    color: '#4F4F4F',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))
export default function Footer() {
  const classes = useStyles()
  const location = useLocation()

  return (
    <SectionContainer backgroundColor={location.pathname !== '/' && 'white'}>
      <Grid
        container
        // direction="row"
        // justifyContent="space-between"
        className={classes.root}
      >
        <Grid item>
          <FooterLeft />
        </Grid>
        <Grid item>
          <FooterRight />
        </Grid>
      </Grid>
      <Box marginTop={5} marginBottom={3}>
        <p className={classes.license}>
          © 2021 Tisoha. All rights reserved. |{' '}
          <NavLink className={classes.linkPrivacy} to="/privacy">
            Privacy policy
          </NavLink>
        </p>
      </Box>
    </SectionContainer>
  )
}
