import * as React from 'react'

function IconPhone(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.655 8.348c2.94 2.938 3.606-.461 5.477 1.409 1.805 1.804 2.841 2.165.556 4.45-.287.23-2.106 2.999-8.5-3.393-6.394-6.392-3.627-8.213-3.397-8.5C4.083.023 4.438 1.066 6.242 2.87c1.872 1.87-1.526 2.54 1.413 5.478z"
        stroke="#BDBDBD"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPhone
