import { Box } from '@material-ui/core'
import React from 'react'
import BodyPrivacyPolicy from './BodyPrivacyPolicy.js'

export default function PrivacyPolicy() {
  return (
    <Box>
      <BodyPrivacyPolicy />
    </Box>
  )
}
