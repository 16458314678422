import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'

import SectionContainer from '../../components/SectionContainer'

import './styles.scss'

const useStyles = makeStyles(theme => ({
  image: {
    height: '99vh'
  }
}))
export default function NotFound() {
  const classes = useStyles()

  return (
    <Box className="error-page">
      <SectionContainer backgroundColor="black">
        <div className="error">404</div>
        <br />
        <br />
        <span className="info">Page not found</span>
        <img
          src="http://images2.layoutsparks.com/1/160030/too-much-tv-static.gif"
          className="static"
        />
      </SectionContainer>
    </Box>
  )
}
