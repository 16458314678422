import * as React from 'react'

function IconGraphic(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.733 39.933V38c0-9.716 6.568-17.935 15.467-20.476M51.267 39.933V38c0-9.716-6.568-17.935-15.467-20.476"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.533 39.933h-11.6v11.6h11.6v-11.6zM57.067 39.933h-11.6v11.6h11.6v-11.6z"
        stroke="#FF5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6 14.8h11.6M35.8 14.8h11.6"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.8 20.6A5.8 5.8 0 106.8 9a5.8 5.8 0 000 11.6zM53.2 20.6a5.8 5.8 0 100-11.6 5.8 5.8 0 000 11.6zM35.8 9H24.2v11.6h11.6V9z"
        stroke="#FF5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconGraphic
