import { Box } from '@material-ui/core'
import React from 'react'
import Icon_agile from '../../components/icons/IconAgile'

export default function DevelopmentMethodology() {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box width={'70%'}>
          <Box marginTop={15} marginBottom={3}>
            <p className={'typo--red'}>Development Methodology</p>
          </Box>
          <Box marginTop={6}>
            <Icon_agile />
          </Box>
          <Box marginTop={15} display="flex" flexDirection="row" justifyContent="space-between">
            {/* <p className={"typo--red"}>Trust by</p> */}
          </Box>
          {/* <Box marginTop={6}>
               <CarouselContent />
            </Box> */}
        </Box>
      </Box>
    </Box>
  )
}
