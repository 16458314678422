import React from 'react'

export default function IconColab(props) {
  return (
    <svg
      width={'90%'}
      height={'90%'}
      viewBox="0 0 320 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.2}
        d="M28.485 133.507C19.946 89.58 53.256 47.945 98.06 40.176c23.895-4.143 58.55-5.652 87.015 23.572 46.01 47.242 123.959 37.758 123.959 107.635 0 60.439-117.031 101.535-210.125 47.457-45.717-26.559-65.001-57.434-70.424-85.333z"
        fill="#fff"
      />
      <path
        d="M38.638 117.393c-3.276-43.808 30.69-81.433 74.701-83.934 23.468-1.336 57.003 1.12 81.114 32.85 38.972 51.29 116.637 54.303 102.969 119.466-11.457 54.608-117.059 86.111-200.592 22.818-41.023-31.077-56.109-63.379-58.192-91.2z"
        fill="url(#paint12_linear)"
      />
      <path d="M158.569 157.112l1.248 7.152 7.846 1.152.922-5.935-10.016-2.369z" fill="#FFB6BE" />
      <path
        d="M256.206 203.454l-3.535 29.645c-13.593 5.29-22.876 7.935-37.796 9.588l-1.16-37.45 42.491-1.783z"
        fill="#97E19A"
      />
      <path
        d="M189.544 139.567s-.817-1.326-.601-1.98c.216-.654 1.312-5.337 1.147-8.442-.165-3.104.197-4.193.018-4.792-.179-.599-1.546-.434-2.092 1.633-.546 2.071-.326 5.172-2.129 6.155-1.803.978-2.73-6.073-2.239-6.686.491-.613 3.06-4.49 2.73-5.089-.326-.599-1.202-.055-2.459 1.143-1.257 1.198-3.116 2.3-3.331 2.844-.22.544-.275 1.633-.275 1.633s-2.148 1.088-2.221 1.907c-.074.818 0 2.016 0 2.016s-1.055.874-.895 1.797c.165.924 3.987 8.579 5.901 10.184l1.541 2.131 4.905-4.454z"
        fill="#FFC3C7"
      />
      <path
        d="M178.087 129.909c.936-.677 2.735-1.82 4.662-2.232 0 0 .564.325-1.019 1.852-1.583 1.523-2.239 1.852-2.184 2.017.055.165.927 1.523 1.638 1.962.711.434 3.79 6.233.12 6.114-1.868-2.877-3.988-7.225-4.107-7.911-.165-.929.89-1.802.89-1.802z"
        fill="#FFC3C7"
      />
      <path
        d="M124.396 210.418v15.509c-14.362-7.806-22.225-12.959-35.85-23.77l8.196-3.689 27.654 11.95z"
        fill="#fff"
      />
      <path
        d="M111.384 122.949a167.795 167.795 0 0117.669 30.88l1.959 4.468 27.406-1.783s2 7.719 1.399 7.746c-.601.028-22.578 10.636-32.59 8.268-7.708-1.825-24.492-35.348-24.492-35.348l8.649-14.231z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M229.319 123.136s-1.152 2.766-5.607 6.447c0 0-1.927-11.628 18.881-12.41l-1.432 1.358-11.842 4.605z"
        fill="#6EC9D1"
      />
      <path
        d="M227.91 126.776s-1.982.137-5.896 5.642c-3.913 5.506-9.754 16.38-15.356 25.146-9.801-7.682-15.398-18.844-15.999-18.844-.601 0-5.832 3.896-6.915 6.132-1.082 2.236 10.315 22.965 17.312 24.359 6.997 1.395 17.306-10.499 17.306-10.499s12.081-25.361 9.548-31.936z"
        fill="#EBEEED"
      />
      <path
        d="M217.44 86.124l-1.831 18.538a.818.818 0 00.904.892l4.79-.507 1.969 5.322 9.988-3.827 7.75-20.976-23.57.558z"
        fill="#FFC3C7"
      />
      <path
        d="M239.243 109.994s.918 8.41 3.024 10.275c0 0-13.086 3.549-14.6 3.179 0 0-.715-4.345-.55-6.137.165-1.793 12.581-21.305 12.581-21.305s6.547.823 6.941 1.235c.395.411-3.578 7.847-3.578 7.847l-3.818 4.906z"
        fill="#FFC3C7"
      />
      <path
        d="M211.173 98.525c.169-2.533 1.537-4.436 3.12-4.33 1.349.087 2.349 1.614 2.505 3.635a2.7 2.7 0 011.656.032c.409-2.035 1.62-3.453 2.987-3.366.785.05 1.473.59 1.945 1.519.331.653.528 1.45.574 2.304l15.435-.65.027.618-15.462.654c-.005.092.005.178 0 .265-.078 1.18-.431 2.273-.986 3.082-.592.856-1.349 1.299-2.129 1.249a1.695 1.695 0 01-.404-.073c-1.358-.398-2.262-2.323-2.111-4.633.009-.114.019-.228.032-.343-.504-.173-1.101-.091-1.537.019 0 .128-.004.26-.013.388-.078 1.18-.432 2.273-.987 3.083-.592.855-1.349 1.298-2.129 1.248a1.713 1.713 0 01-.404-.073c-1.367-.394-2.275-2.314-2.119-4.628zm9.704 4.399c.555.037 1.119-.311 1.578-.983.496-.718.808-1.706.881-2.776.073-1.07-.106-2.09-.505-2.867-.307-.608-.715-1.001-1.17-1.134a1.046 1.046 0 00-.266-.046c-1.193-.077-2.317 1.642-2.459 3.76-.138 2.112.752 3.964 1.941 4.046zm-7.149-.306c.556.036 1.12-.311 1.579-.983.495-.718.807-1.706.881-2.776.004-.096.009-.188.009-.28v-.196c-.009-1.756-.721-3.242-1.689-3.525a1.309 1.309 0 00-.261-.046c-1.193-.078-2.317 1.642-2.459 3.759-.138 2.112.747 3.964 1.94 4.047z"
        fill="#004469"
      />
      <path
        opacity={0.3}
        d="M237.238 105.654s-3.542 2.703-7.107 5.876c-1.707 2.908-2.969 5.218-3.019 5.785-.083.91.064 2.478.225 3.804 7.557-2.41 9.901-15.465 9.901-15.465z"
        fill="#FFC3C7"
      />
      <path
        d="M123.625 89.755l1.4 15.548h-3.042s-.22 9.612-1.289 9.964c-2.643.878-10.865-2.392-10.865-2.392s-.363 1.953-.533 3.86c-.188 2.131.533 4.513.533 4.513-.202 3.787-11.333.124-12.732-3.466 0 0 1.44-5.14 1.238-7.929-.046-.672 1.175-3.672 1.175-3.672-1.046-4.577-4.079-11.784-4.079-12.237 0-.599 9.998-12.356 10.599-12.16.596.197 17.595 7.971 17.595 7.971z"
        fill="#FFC3C7"
      />
      <path
        d="M115.945 106.473a.464.464 0 01.128-.645.468.468 0 01.647.128c1.335 1.999 3.68 2.246 5.144 2.186l-.056.947c-.114.004-.238.009-.362.009-1.67-.005-4.042-.444-5.501-2.625z"
        fill="#F57797"
      />
      <path
        d="M109.824 121.243s10.2 9.365 11.796 29.696c1.602 20.331 5.171 57.489 2.772 59.483-1.441 1.197-13.186 1.715-23.552 1.16-7.194-4.976-11.47-8.614-19.262-15.676-2.806-19.358-3.35-54.171 1.047-63.515 6.002-12.753 14.467-14.61 14.467-14.61s4.808 5.049 12.732 3.462z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M183.029 156.83s-6.213-2.548-9.149-2.593c-2.932-.046-3.602 1.294-3.602 1.294s2.4 1.317 1.198 1.847c-1.198.531-7.975 5.501-8.218 7.39-.082.663 1.06.558 1.684.855 1.523.736 1.537-.315 2.68 1.125.835 1.052 1.491-.366 2.688.695 1.198 1.061 11.072-3.613 11.072-3.613l1.647-7z"
        fill="#FFC3C7"
      />
      <path
        d="M158.569 157.113c.798-.201 6.396-2.094 9.025-2.986a3.61 3.61 0 012.693.147c2.34 1.088 6.896 3.315 6.396 3.909-.665.796-2.652.458-4.533 0-1.881-.457-3.569 1.299-3.569 1.299l-10.012-2.369zM179.978 164.397s-3.249-4.554-4.272-3.128c-1.023 1.427-2.198.362-2.542.897-.344.53-2.744.023-2.611 1.673.133 1.651.133 3.563 1.336 3.668 1.197.105 7.914-1.541 8.089-3.11z"
        fill="#FFB6BE"
      />
      <path
        opacity={0.3}
        d="M109.824 112.87s-.298 1.601-.477 3.316c-4.662-2.099-6.607-10.006-6.607-10.006 1.987 3.645 7.084 6.69 7.084 6.69zM217.023 90.34l.417-4.22 6.47-.151 9.194 3.31c0 .005-8.396 2.434-16.081 1.062z"
        fill="#FFC3C7"
      />
      <path
        d="M211.934 75.268c.349-.864 1.368-1.23 2.203-.81 1.514.769 4.29 1.688 8.162.975 2.189-.403 9.58-3.494 16.339-1.075 2.367.846 4.166 4.152 3.964 6.013 0 0 10.745 1.418 6.841 15.516-.009.027-.014.06-.019.087-.004.009-.004.023-.009.032-2.037 8.77-10.172 13.988-10.172 13.988-.215-.773-.188-2.195-.128-3.201.032-.544.404-.988.922-1.166 2.377-.819 6.864-6.86 2.776-8.19-4.643-1.514-4.107 7.376-9.232 15.968-4.253 7.134-13.36 5.259-16.343 3.599-4.877-2.707-.183-11.505-.183-11.505s2.422-.27 4.244-.448c.206-.019.399-.023.578-.014 1.046.055 1.945.755 2.335 1.724l.243.608c.248.613.909.947 1.547.778 1.803-.476 5.299-1.542 6.786-3.028 1.5-1.504 3.689-7.618 4.744-10.76.339-1.015.399-2.121.064-3.141-.417-1.285-1.468-2.56-4.07-2.021-4.799.997-18.022 2.414-21.757-6.224-1.051-3.457-.468-6.123.165-7.705z"
        fill="#004469"
      />
      <path
        opacity={0.2}
        d="M241.005 97.438s-2.193.562-3.969 5.446c-1.734 4.76-5.138 12.904-13.475 13.137a12.562 12.562 0 01-1.666-.082c-1.665-.169-4.978-.645-6.74-1.861.248 1.198.867 2.25 2.083 2.927 2.983 1.655 12.095 3.534 16.343-3.599 4.341-7.276 4.621-14.757 7.424-15.969zM217.055 105.498s2.422-.269 4.244-.448c.206-.018.399-.023.578-.013 1.046.054 1.945.754 2.335 1.724l.244.608s-1.363-1.637-4.217-1.038c-1.308.274-2.831.768-4.102 1.221.477-1.226.918-2.054.918-2.054z"
        fill="#004469"
      />
      <path
        d="M220.381 141.519c.702-4.211 1.845-8.345 3.478-12.292 1.193-2.876 3.808-5.775 3.808-5.775 1.257-.274 5.703.425 9.099-.412 4.097-1.006 7.125-3.539 7.125-3.539s12.315 5.355 12.627 48.943c.027 4.01-.005 8.021.491 11.999.514 4.12 1.115 8.899 1.142 9.338.454 7.22 0 11.756-.243 13.512-.069.508-.459.91-.968.993-4.116.69-14.081.887-16.031.772-1.303.087-2.652.17-4.038.234-10.599.498-23.056.151-23.166-.051-5.395-10.179 4.295-49.423 6.676-63.722z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M110.87 83.943a6767.002 6767.002 0 0112.751 5.808l.335 3.699c-3.308.722-9.402 1.134-14.939-3.43 0 0-.12 4.093-5.593 4.715l7.446-10.792z"
        fill="#FFC3C7"
      />
      <path
        d="M91.361 85.9c-1.601-2.259-3.143-11.738 8.562-12.168l-.234-.1s.688 0 1.84.004c.477-.01.972-.005 1.482.013 7.722.096 25.381-.023 26.125 2.803.55 2.095-2.799 3.682-4.111 4.39 4.937 1.427 6.611 2.918 5.712 4.688-4.542 8.99-15.678 6.14-22.381 3.576.225 4.335-4.932 5.629-4.932 5.629s-2.735-4.184-5.268-.202c-1.702 5.584 3.625 6.631 3.625 6.631s-1.331 3.727-3.317 7.925c-.326-.243-17.022-14.656-7.103-23.189z"
        fill="#004469"
      />
      <path
        opacity={0.4}
        d="M248.667 145.447s-9.029 32.732-36.026 40.593c.367-3.612.899-7.449 1.523-11.336 6.396-4.655 12.925-8.789 12.925-8.789l21.578-20.468zM258.156 189.776c.454 7.221 0 11.757-.243 13.513-.069.507-.459.91-.969.992-2.959.494-8.947.736-12.796.791 11.383-12.497 12.425-31.557 12.425-31.557.009.526.027 1.052.046 1.578.004.091.004.187.009.279.018.439.037.878.064 1.317.009.141.014.288.023.43.028.411.055.818.092 1.23.014.151.023.301.036.448.051.548.106 1.093.175 1.637.115.901.55 4.449 1.073 8.67.033.357.056.59.065.672zM246.667 121.962s-5.148 8.711-19.527 10.399l18.219-11.789 1.308 1.39z"
        fill="#F1F2F2"
      />
      <path
        d="M241.936 117.484s-2.023 1.994-4.698 3.311c-3.028 1.49-6.887 2.346-9.571 2.652 0 0-2.528 3.928-.527 8.912 0 0 14.691-3.841 18.651-12.694a.909.909 0 00-.115-.905c-1.991-2.703-3.74-1.276-3.74-1.276z"
        fill="#6EC9D1"
      />
      <path
        opacity={0.5}
        d="M94.642 118.751c1.496-.759 2.455-.974 2.455-.974s4.803 5.049 12.732 3.467c0 0 .523.484 1.349 1.458-2.418.983-8.773 2.511-16.536-3.951z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity={0.5}
        d="M155.821 156.632l2.597-.169s2 7.719 1.399 7.746c-.115.005-1.028.403-2.491 1.025l-1.505-8.602z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M234.517 134.633c-.422.631-16.958 23.792-21.78 25.233-13.503-.961-29.24-4.084-29.24-4.084s-2.106 4.102-2.106 8.899c14.709 10.325 32.714 13.211 34.806 13.691 2.087.48 26.203-14.661 32.47-32.924 1.482-4.482 2.877-9.923-1.84-13.527-3.509-2.679-8.758-2.588-12.31 2.712z"
        fill="#F1F2F2"
      />
      <path
        d="M92.66 174.11a23.976 23.976 0 00-.799 8.88c.62 6.339 2.476 17.858 2.91 24.494L79.327 193.96c-1.311-4.736-2.501-9.207-3.22-11.939a14.536 14.536 0 01-.367-5.501c.972-7.829 3.771-29.993 5.152-37.584.523-2.881 1.702-5.62 3.547-7.898 2.523-3.118 6.575-5.825 12.025-2.226 2.313 1.527 3.478 5.898 3.185 10.325-.803 11.953-4.887 27.56-6.988 34.973z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M253.541 43.829c-5.34-4.803-7.349-9.006-8.176-15.065-.885-6.482 2.521-22.2 15.921-26.03 28.263-8.08 51.086 3.014 57.019 16.812 5.148 11.97 1.977 44.345-59.103 26.388l-13.837 6.9 8.176-9.005z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M61.39 90.716l-7.02-13.48s13.773-5.482 9.661-23.87c-4.111-18.39-41.008-22.073-55.477-11.97C-7.924 52.906-3.473 93.8 47.57 81.81l13.822 8.907z"
        fill="url(#paint7_linear)"
      />
      <g opacity={0.22}>
        <path
          opacity={0.22}
          d="M52.981 89.577a2.349 2.349 0 002.354 2.346 2.349 2.349 0 002.353-2.346 2.352 2.352 0 00-2.353-2.346 2.352 2.352 0 00-2.354 2.346z"
          fill="url(#paint8_linear)"
        />
        <path
          opacity={0.22}
          d="M40.382 89.577a2.349 2.349 0 002.354 2.346 2.349 2.349 0 002.353-2.346 2.352 2.352 0 00-2.353-2.346 2.352 2.352 0 00-2.354 2.346z"
          fill="url(#paint9_linear)"
        />
        <path
          opacity={0.22}
          d="M27.04 89.577a2.349 2.349 0 002.353 2.346 2.349 2.349 0 002.354-2.346 2.352 2.352 0 00-2.354-2.346 2.352 2.352 0 00-2.354 2.346z"
          fill="url(#paint10_linear)"
        />
      </g>
      <path
        d="M45.041 58.427a2.773 2.773 0 002.78 2.77 2.777 2.777 0 002.778-2.77 2.773 2.773 0 00-2.779-2.77 2.773 2.773 0 00-2.779 2.77zM30.166 58.427a2.773 2.773 0 002.779 2.77 2.773 2.773 0 002.779-2.77 2.773 2.773 0 00-2.78-2.77 2.77 2.77 0 00-2.778 2.77zM14.412 58.427a2.773 2.773 0 002.78 2.77 2.773 2.773 0 002.779-2.77 2.773 2.773 0 00-2.78-2.77 2.77 2.77 0 00-2.778 2.77z"
        fill="#fff"
      />
      <g opacity={0.2}>
        <path
          opacity={0.2}
          d="M242.372 46.683a2.35 2.35 0 01-2.353-2.345 2.35 2.35 0 012.353-2.346 2.35 2.35 0 012.354 2.346 2.35 2.35 0 01-2.354 2.345z"
          fill="url(#paint11_linear)"
        />
        <path
          opacity={0.2}
          d="M227.42 44.338a2.351 2.351 0 002.353 2.346 2.349 2.349 0 002.354-2.346 2.349 2.349 0 00-2.354-2.346 2.342 2.342 0 00-2.353 2.346z"
          fill="url(#paint12_linear)"
        />
        <path
          opacity={0.2}
          d="M216.431 46.684a2.35 2.35 0 01-2.354-2.346 2.35 2.35 0 012.354-2.346 2.35 2.35 0 012.354 2.346 2.35 2.35 0 01-2.354 2.346z"
          fill="url(#paint13_linear)"
        />
      </g>
      <path
        d="M293.203 25.409a2.866 2.866 0 002.872 2.862 2.866 2.866 0 002.872-2.862 2.866 2.866 0 00-2.872-2.863 2.87 2.87 0 00-2.872 2.863zM277.836 25.406a2.866 2.866 0 002.872 2.862 2.866 2.866 0 002.872-2.862 2.866 2.866 0 00-2.872-2.862 2.866 2.866 0 00-2.872 2.862zM261.557 25.406a2.866 2.866 0 002.872 2.862 2.866 2.866 0 002.872-2.862 2.866 2.866 0 00-2.872-2.862 2.87 2.87 0 00-2.872 2.862z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={261.014}
          y1={105.417}
          x2={0.421901}
          y2={105.417}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70AADE" />
          <stop offset={1} stopColor="#31BB71" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1={159.931}
          y1={147.914}
          x2={102.738}
          y2={147.914}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE9588" />
          <stop offset={1} stopColor="#FD5C63" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1={125.203}
          y1={164.79}
          x2={79.4032}
          y2={164.79}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE9588" />
          <stop offset={1} stopColor="#FD5C63" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1={111.175}
          y1={120.688}
          x2={94.6418}
          y2={120.688}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE9588" />
          <stop offset={1} stopColor="#FE7C64" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1={159.931}
          y1={160.849}
          x2={155.82}
          y2={160.849}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE9588" />
          <stop offset={1} stopColor="#FE7C64" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1={106.773}
          y1={183.363}
          x2={67.1425}
          y2={137.784}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE9588" />
          <stop offset={1} stopColor="#FF7C81" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1={331.27}
          y1={-4.8598}
          x2={185.355}
          y2={88.782}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9173EE" />
          <stop offset={1} stopColor="#E783D9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1={64.8001}
          y1={63.3014}
          x2={0.00213514}
          y2={63.3014}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3DA9C" />
          <stop offset={1} stopColor="#6BD79E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1={57.6913}
          y1={89.5769}
          x2={52.9808}
          y2={89.5769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3DA9C" />
          <stop offset={1} stopColor="#6BD79E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1={45.0924}
          y1={89.5769}
          x2={40.3818}
          y2={89.5769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3DA9C" />
          <stop offset={1} stopColor="#6BD79E" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1={31.751}
          y1={89.5769}
          x2={27.0405}
          y2={89.5769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3DA9C" />
          <stop offset={1} stopColor="#6BD79E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1={244.727}
          y1={44.3353}
          x2={240.017}
          y2={44.3353}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC8AB3" />
          <stop offset={1} stopColor="#E783D9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1={232.128}
          y1={44.3358}
          x2={227.418}
          y2={44.3358}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC8AB3" />
          <stop offset={1} stopColor="#E783D9" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1={218.787}
          y1={44.3358}
          x2={214.076}
          y2={44.3358}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC8AB3" />
          <stop offset={1} stopColor="#E783D9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
