import React from "react";
import IconArrowButton from "../icons/IconArrowButton";
const IdeaBlock = () => {
  return (
    <div className="idea-block">
      <div className="idea-block__container">
        <div className="idea-block__wrapper">
          <div className="idea-block__left">
            <h3 className="idea-block__left__title" data-wow-delay="0.1s">
              We turn <span>your ideas </span>to tailor-made software
            </h3>
            <a className="idea-block__left__button" title="Get a quote">
              Get a quote
              <IconArrowButton />
            </a>
            <div className="idea-block__left__image">
              <img
                src="https://adamosoft.com/thumbnail?file=adamo-software1651246241Uau5vO.png&width=419&height=209"
                alt="as"
              />
            </div>
          </div>
          <div className="idea-block__right">
            <div className="idea-block__right__image">
              <img
                src="https://adamosoft.com/thumbnail?file=adamo-software1651246241iaXj4R.png&width=379&height=379"
                alt="asdw"
              />
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdeaBlock;
