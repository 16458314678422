import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from 'antd'
import React from 'react'
import IconCircle from '../icons/IconCircle'
import IconMobileApp from '../icons/IconMobileApp'
import TypoTitle from '../Typography/TypoTitle'

const useStyles = makeStyles(theme => ({
  titlename: {
    position: 'static',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '42px',
    color: 'white',
    '-webkit-text-stroke': '1px black',

    [theme.breakpoints.up('md')]: {
      fontSize: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 40
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 40
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  BoxServices: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  spanCircle: {
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}))
export default function GroupMobileApp(props) {
  const classes = useStyles(props)

  return (
    <Box marginTop={10} className={classes.BoxServices}>
      <Box alignItems="center" flexDirection="row" width={'300px'}>
        <Card hoverable cover={<IconMobileApp />}>
          <TypoTitle text={'Mobile App'} fontSize={'4vw'} />
          <Box className={classes.titlename}>
            <span className={classes.namecontent}>Development</span>
          </Box>
        </Card>
      </Box>

      <Box className={classes.spanCircle} width={'500px'}>
        <Box>
          <span>
            A custom mobile app development company brings you closer to your customers at the right
            time and the right place, TISOHA focuses on building outstanding mobile application
            software in a short time and affordable price. We promise to lay the foundation for
            valuable, long term relationships with your customers to deliver your software
            outsourcing projects.
          </span>
        </Box>
        <Box display="flex" marginTop={1} alignItems="center">
          <Box height={15} width={15} marginRight={1}>
            <IconCircle />
          </Box>

          <span>Native: Android, iOS</span>
        </Box>
        <Box display="flex" alignItems="center">
          <Box height={15} width={15} marginRight={1}>
            <IconCircle />
          </Box>

          <span>Hybric: Flutter, React Native</span>
        </Box>
      </Box>
    </Box>
  )
}
