import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { memo } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './styles.scss'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 550
    },
    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: 500
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 320
    }
  },
  slider: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 550
    },
    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: 500
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 320
    }
  },
  cardContainer: {
    display: 'flex',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: 550
    },
    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: 500
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 320
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  avatar: {
    borderRadius: '50%'
  },
  name: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',

    color: '#FFFFFF'
  },
  place: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    // lineHeight: '24px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    opacity: '0.6'
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    opacity: 0.6,

    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      lineHeight: '20px'
    }
  },
  dots: {
    position: 'absolute',
    bottom: '-25px',
    display: 'block',
    width: '100%',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    textAlign: 'center'
  },
  dotsContainer: {
    width: '100%'
  }
}))

const CustomSlide = memo(props => {
  const classes = useStyles()
  const { data } = props
  const { name, place, imageLink, text } = data

  return (
    <Box className={classes.cardContainer}>
      <Box className={classes.details}>
        <Box className={classes.content}>
          <Box display="flex" marginBottom={2}>
            <Box marginRight={2}>
              <img
                alt=""
                width={64}
                height={64}
                src={imageLink.default}
                className={classes.avatar}
              />
            </Box>
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
              <Typography className={classes.name}>{name}</Typography>
              <Typography className={classes.place}>{place}</Typography>
            </Box>
          </Box>
          <Typography className={classes.text}>{text}</Typography>
        </Box>
      </Box>
    </Box>
  )
})
CustomSlide.displayName = 'Custom Slide'
CustomSlide.propTypes = {
  data: PropTypes.object.isRequired
}

function CustomDotsCarousel() {
  const classes = useStyles()

  const settings = {
    arrows: false,
    dots: true,
    dotsClass: 'dots',
    infinite: true,
    speed: 1000,
    autoplay: true,
    // autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const data = [
    {
      name: 'Allyssa Deanna',
      place: 'Chief Product Officer and Co-founder at Halalfoodhunt, Singapore',
      imageLink: require('../../assets/images/client_dee.jpeg'),
      text: 'We have had one of the best experiences working with Ba Do and the team. Tisoha has been supporting our business for the past 6 months in creating and implementing new and tailored mobile applications. We have worked with several of their developers and cannot speak highly enough of the team. They are reliable, thorough, smart, available, extremely good communicators, and very friendly! We would recommend hiring Tisoha to anyone looking for a highly productive and solution-driven team. We plan to continue to work with them for the long term.'
    },
    {
      name: 'Souhail Alavi',
      place: 'President/CEO at Digital Fractal Technologies Inc, Canada',
      imageLink: require('../../assets/images/client_souhail.jpeg'),
      text: 'We are very pleased with TISOHA team. They are very detail-oriented and were able to execute the project within an acceptable timeline and budget. Would definitely team up with them.'
    },
    {
      name: 'Trina Amiot',
      place: 'Founder of www.TheMoonTree.org, Canada',
      imageLink: require('../../assets/images/client_trina.jpeg'),
      text: 'TISOHA was helpful in creating our Power of 8 chakra oracle card app from the very start! They kept us on track, met timelines, and had awesome communication. This was my first time creating an app and They were kind and hardworking the whole time. The team is also very understanding and has a good heart. I will always recommend them and will work with them on our next one!'
    },
    {
      name: 'William Burden',
      place: 'Websitings Pty Ltd Australia, Australia',
      imageLink: require('../../assets/images/client_no_name.jpeg'),
      text: 'TISOHA has developed several apps for me over the last couple of years. The apps ranged from a custom rideshare app to a retail futures trading app and a couple of apps in between. Their greatest skill is grasping concepts quickly, delivering work on time, and responding quickly. In summary, I cannot fault their work and would recommend them for app development.'
    },
    {
      name: 'Kumar Amit',
      place: 'Director - Trixtar Software, India',
      imageLink: require('../../assets/images/client_kumar.jpeg'),
      text: 'We used TISOHA for some of our projects. I was really impressed by the professionalism, work quality and dedication towards the project. We see TISOHA as our trusted development partners and highly recommend them to others!'
    }
  ]

  return (
    <Box className={classes.slider}>
      <Slider {...settings}>
        {data.map((item, index) => (
          <CustomSlide key={index} data={item} />
        ))}
      </Slider>
    </Box>
  )
}
export default CustomDotsCarousel
