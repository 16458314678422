import React from 'react'

export default function IconPlatformWeb(props) {
  return (
    <svg
      width={227}
      height={180}
      viewBox="0 0 227 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={0.5} y={0.5} width={226} height={147} rx={11.5} stroke="#000" />
      <rect x={8.5} y={8.5} width={210} height={123} rx={7.5} stroke="#000" />
      <rect x={43.5} y={164.5} width={140} height={15} rx={3.5} stroke="#000" />
    </svg>
  )
}
