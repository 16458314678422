import { Box, Toolbar } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'

import { ScrollTop } from '../../components/BackToTop'
import BusinessModels from '../../components/BusinessModels'
import Footer from '../../components/Footer/Footer.js'
import Platforms from '../../components/Platforms'
import SectionContainer from '../../components/SectionContainer'
import Testimonials from '../../components/Testimonials'
import TrendingTechs from '../../components/TrendingTechs'
import TypoOutlined from '../../components/Typography/TypoOutlined'
import TypoTitle from '../../components/Typography/TypoTitle'
import WhoWeAre from '../../components/WhoWeAre'
import WhyWorkWithUs from '../../components/WhyWorkWithUs'

import '../../scss/styles.scss'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white'
  },
  headerSection: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(6)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4)
    }
  },
  heroTitle: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    }
  },
  testimonial: {
    margin: '12 auto'
  }
}))
export default function Homepage(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar id="back-to-top-anchor" style={{ backgroundColor: 'white' }} />
      <SectionContainer backgroundColor="black">
        <Box className={classes.heroTitle}>
          <Box marginTop={4}>
            <TypoTitle text={'We'} color={'white'} fontSize={'4vw'} />
          </Box>
          <Box>
            <TypoOutlined text={'DESIGN.'} color={'black'} fontSize={'11vw'} />
            <TypoOutlined text={'DEVELOP.'} color={'black'} fontSize={'11vw'} />
            <TypoOutlined text={'DELIVER.'} color={'black'} fontSize={'11vw'} />
          </Box>
        </Box>

        {/* <CustomCarousel /> */}
        <WhoWeAre />
      </SectionContainer>

      <SectionContainer backgroundColor="white">
        <Box className={classes.headerSection}>
          <Box className={classes.heroTitle}>
            <TypoTitle text={'Technologies'} color={'black'} fontSize={'4vw'} />
          </Box>
          <Box>
            <TypoOutlined text={'WE ARE EXPERT IN.'} color={'white'} fontSize={'11vw'} />
          </Box>
        </Box>

        <Box>
          <TrendingTechs />
        </Box>

        {/* <Box marginTop={8}>
               <Services />
            </Box> */}
        <Box>
          <Platforms />
        </Box>
      </SectionContainer>

      <SectionContainer backgroundColor="black">
        <Box marginTop={12}>
          <WhyWorkWithUs />
        </Box>
        <Box marginTop={12}>
          <BusinessModels />
        </Box>

        <Box marginTop={12} marginBottom={30}>
          <Testimonials />
        </Box>
      </SectionContainer>

      <Box marginTop={-16}>
        <Footer />
      </Box>

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  )
}
