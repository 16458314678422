import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import SectionContainer from '../SectionContainer'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '80px'
  },
  testimonial: {
    margin: '12 auto'
  },
  heading: {
    color: 'black',
    textAlign: 'justify',
    fontFamily: 'BlinkMacSystemFont',
    letterSpacing: '.03em',
    [theme.breakpoints.up('md')]: {
      fontSize: 40
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 30
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  textbody: {
    textAlign: 'justify',
    paddingBottom: '20px',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '.02em',
    fontFamily: 'RobotoSlabLight'
  }
}))
function BodyPrivacyPolicy() {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <SectionContainer backgroundColor="white">
        <Box className={classes.wrapper}>
          <h1 className={classes.heading}>PRIVACY POLICY</h1>
          <p>Effective date: 2021-08-01</p>
          <Box className="Introduction">
            <h1 className={classes.heading}>1. Introduction</h1>
            <span>
              Welcome to <strong>TISOHA Ltd., Co.</strong>
            </span>
            <p className={classes.textbody}>
              <strong>TISOHA Ltd., Co.</strong> (“us”, “we”, or “our”) operates www.tisoha.com
              (hereinafter referred to as <strong> “Service”</strong>).
            </p>
            <p className={classes.textbody}>
              Our Privacy Policy governs your visit to www.tisoha.com , and explains how we collect,
              safeguard and disclose information that results from your use of our Service.
            </p>
            <p className={classes.textbody}>
              We use your data to provide and improve Service. By using Service, you agree to the
              collection and use of information in accordance with this policy. Unless otherwise
              defined in this Privacy Policy, the terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions.
            </p>
            <p className={classes.textbody}>
              Our Terms and Conditions <strong>(“Terms”)</strong> govern all use of our Service and
              together with the Privacy Policy constitutes your agreement with us
              <strong> (“agreement”)</strong>.
            </p>
          </Box>
          <Box className="Definitions">
            <h1 className={classes.heading}>2. Definitions</h1>
            <p className={classes.textbody}>
              <strong>SERVICES</strong> means the www.tisoha.com website operated by TISOHA Ltd.,
              Co.
            </p>
            <p className={classes.textbody}>
              <strong>PERSONAL DATA</strong> means data about a living individual who can be
              identified from those data (or from those and other information either in our
              possession or likely to come into our possession).
            </p>
            <p className={classes.textbody}>
              <strong> USAGE DATA</strong>, is data collected automatically either generated by the
              use of Service or from Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
            <p className={classes.textbody}>
              <strong>COOKIES</strong> are small files stored on your device (computer or mobile
              device).
            </p>
            <p className={classes.textbody}>
              <strong>DATA CONTROLLER</strong>, means a natural or legal person who (either alone or
              jointly or in common with other persons) determines the purposes for which and the
              manner in which any personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
            </p>
            <p className={classes.textbody}>
              <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal
              person who processes the data on behalf of the Data Controller. We may use the
              services of various Service Providers in order to process your data more effectively.
            </p>
            <p>
              <strong>DATA SUBJECT</strong> is any living individual who is the subject of Personal
              Data.
            </p>
            <p className={classes.textbody}>
              <strong>THE USER</strong> is the individual using our Service. The User corresponds to
              the Data Subject, who is the subject of Personal Data.
            </p>
          </Box>
          <Box className="InformationCollection">
            <h1 className={classes.heading}>3. Information Collection and Use</h1>
            <span>
              {' '}
              We collect several different types of information for various purposes to provide and
              improve our Service to you.
            </span>
          </Box>
          <Box className="TypesOfData">
            <h1 className={classes.heading}>4. Types of Data Collected</h1>
            <h3>Personal Data</h3>
            <p className={classes.textbody}>
              While using our Service, we may ask you to provide us with certain personally
              identifiable information that can be used to contact or identify you{' '}
              <strong>(“Personal Data”)</strong>. Personally, identifiable information may include,
              but is not limited to:
            </p>
            <p>0.1. Email address</p>
            <p>0.2. First name and last name</p>
            <p>0.3. Phone number</p>
            <p className={classes.textbody}>
              0.4. Address, Country, State, Province, ZIP/Postal code, City
            </p>
            <p>0.5. Cookies and Usage Data</p>
            <p>
              We may use your Personal Data to contact you with newsletters, marketing or
              promotional materials and other information that may be of interest to you. You may
              opt out of receiving any, or all, of these communications from us by following the
              unsubscribe link.
            </p>
            <h3 className={classes.heading}>Usage Data</h3>
            <p className={classes.textbody}>
              We may also collect information that your browser sends whenever you visit our Service
              or when you access Service by or through any device
              <strong>(“Usage Data”)</strong> .
            </p>
            <p>
              This Usage Data may include information such as your computer’s Internet Protocol
              address (e.g. IP address), browser type, browser version, the pages of our Service
              that you visit, the time and date of your visit, the time spent on those pages, unique
              device identifiers and other diagnostic data.
            </p>
            <p className={classes.textbody}>
              When you access Service with a device, this Usage Data may include information such as
              the type of device you use, your device unique ID, the IP address of your device, your
              device operating system, the type of Internet browser you use, unique device
              identifiers and other diagnostic data.
            </p>
            <h3 className={classes.heading}>Location Data</h3>
            <p className={classes.textbody}>
              We may use and store information about your location if you give us permission to do
              so (“Location Data”). We use this data to provide features of our Service, to improve
              and customize our Service.
            </p>
            <p className={classes.textbody}>
              You can enable or disable location services when you use our Service at any time by
              way of your device settings.
            </p>
            <h3 className={classes.heading}>Tracking Cookies Data</h3>
            <p className={classes.textbody}>
              We use cookies and similar tracking technologies to track the activity on our Service
              and we hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an anonymous unique
              identifier. Cookies are sent to your browser from a website and stored on your device.
              Other tracking technologies are also used such as beacons, tags and scripts to collect
              and track information and to improve and analyze our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be able to use some
              portions of our Service.
            </p>
            <p className={classes.textbody}>Examples of Cookies we use:</p>
            <p className={classes.textbody}>
              <strong>0.1. Session Cookies:</strong> We use Session Cookies to operate our Service.
            </p>
            <p className={classes.textbody}>
              <strong>0.2. Preference Cookies:</strong> We use Preference Cookies to remember your
              preferences and various settings.
            </p>
            <p className={classes.textbody}>
              <strong>0.3. Security Cookies:</strong> We use Security Cookies for security purposes.
            </p>
            <p className={classes.textbody}>
              <strong>0.4. Advertising Cookies:</strong> Advertising Cookies are used to serve you
              with advertisements that may be relevant to you and your interests.
            </p>
            <h3 className={classes.heading}>Other Data</h3>
            <p className={classes.textbody}>
              While using our Service, we may also collect the following information: sex, age, date
              of birth, place of birth, passport details, citizenship, registration at place of
              residence and actual address, telephone number (work, mobile), details of documents on
              education, qualification, professional training, employment agreements, information on
              bonuses and compensation, information on marital status, family members, social
              security (or other taxpayer identification) number, office location and other data.
            </p>
          </Box>
          <Box className="UseOfData ">
            <h1 className={classes.heading}>5. Use of Data</h1>
            <p className={classes.textbody}>
              TISOHA Ltd., Co. uses the collected data for various purposes:
              <br />
              0.1. to provide and maintain our Service;
              <br />
              0.2. to notify you about changes to our Service;
              <br />
              0.3. to allow you to participate in interactive features of our Service when you
              choose to do so;
              <br />
              0.4. to provide customer support;
              <br />
              0.5. to gather analysis or valuable information so that we can improve our Service;
              <br />
              0.6. to monitor the usage of our Service;
              <br />
              0.7. to detect, prevent and address technical issues;
              <br />
              0.8. to fulfil any other purpose for which you provide it;
              <br />
              0.9. to carry out our obligations and enforce our rights arising from any contracts
              entered into between you and us, including for billing and collection;
              <br />
              0.10. to provide you with notices about your account and/or subscription, including
              expiration and renewal notices, email-instructions, etc.;
              <br />
              0.11. to provide you with news, special offers and general information about other
              goods, services and events which we offer that are similar to those that you have
              already purchased or enquired about unless you have opted not to receive such
              information;
              <br />
              0.12. in any other way we may describe when you provide the information;
              <br />
              0.13. for any other purpose with your consent.
              <br />
            </p>
          </Box>
          <Box className="RetentionOfData">
            <h1 className={classes.heading}>6. Retention of Data</h1>
            <p className={classes.textbody}>
              We will retain your Personal Data only for as long as is necessary for the purposes
              set out in this Privacy Policy. We will retain and use your Personal Data to the
              extent necessary to comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve disputes, and enforce our
              legal agreements and policies.
              <br />
              We will also retain Usage Data for internal analysis purposes. Usage Data is generally
              retained for a shorter period, except when this data is used to strengthen the
              security or to improve the functionality of our Service, or we are legally obligated
              to retain this data for longer time periods.
            </p>
          </Box>
          <Box className="TransferOfData">
            <h1 className={classes.heading}>7. Transfer of Data</h1>
            <p className={classes.textbody}>
              Your information, including Personal Data, may be transferred to – and maintained on –
              computers located outside of your state, province, country or other governmental
              jurisdiction where the data protection laws may differ from those of your
              jurisdiction.
              <br />
              If you are located outside Vietnam and choose to provide information to us, please
              note that we transfer the data, including Personal Data, to Vietnam and process it
              there.
              <br />
              Your consent to this Privacy Policy followed by your submission of such information
              represents your agreement to that transfer.
              <br />
              TISOHA Ltd., Co. will take all the steps reasonably necessary to ensure that your data
              is treated securely and in accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organisation or a country unless there are
              adequate controls in place including the security of your data and other personal
              information.
            </p>
          </Box>
          <Box className="DisclosureOfData">
            <h1 className={classes.heading}>8. Disclosure of Data</h1>
            <p className={classes.textbody}>
              We may disclose personal information that we collect, or you provide:
              <br />
              <strong>0.1. Disclosure for Law Enforcement.</strong>
              <br />
              Under certain circumstances, we may be required to disclose your Personal Data if
              required to do so by law or in response to valid requests by public authorities.
              <br />
              <strong>0.2. Business Transaction.</strong>
              <br />
              If we or our subsidiaries are involved in a merger, acquisition or asset sale, your
              Personal Data may be transferred.
              <br />
              <strong>0.3. Other cases. We may disclose your information also:</strong>
              <br />
              0.3.1. to our subsidiaries and affiliates;
              <br />
              0.3.2. to contractors, service providers, and other third parties we use to support
              our business;
              <br />
              0.3.3. to fulfill the purpose for which you provide it;
              <br />
              0.3.4. for the purpose of including your company’s logo on our website;
              <br />
              0.3.5. for any other purpose disclosed by us when you provide the information;
              <br />
              0.3.6. with your consent in any other cases;
              <br />
              0.3.7. if we believe disclosure is necessary or appropriate to protect the rights,
              property, or safety of the Company, our customers, or others.
            </p>
          </Box>
          <Box className="SecurityOfData">
            <h1 className={classes.heading}>9. Security of Data</h1>
            <p className={classes.textbody}>
              The security of your data is important to us but remember that no method of
              transmission over the Internet or method of electronic storage is 100% secure. While
              we strive to use commercially acceptable means to protect your Personal Data, we
              cannot guarantee its absolute security.
            </p>
          </Box>
          <Box className="YourDataProtection... ">
            <h1 className={classes.heading}>
              10. Your Data Protection Rights under the Vietnam Privacy Protection Act
            </h1>
            <p className={classes.textbody}>
              VPPA is the first state law in the nation to require commercial websites and online
              services to post a privacy policy. The law’s reach stretches well beyond Vietnam to
              require a person or company in the Vietnam (and conceivable the world) that operates
              websites collecting personally identifiable information from consumers to post a
              conspicuous privacy policy on its website stating exactly the information being
              collected and those individuals with whom it is being shared, and to comply with this
              policy.
              <br />
              According to VPPA we agree to the following:
              <br />
              0.1. users can visit our site anonymously;
              <br />
              0.2. our Privacy Policy link includes the word “Privacy”, and can easily be found on
              the home page of our website;
              <br />
              0.3. users will be notified of any privacy policy changes on our Privacy Policy Page;
              <br />
              0.4. users are able to change their personal information by emailing us at
              bus.tisoha@gmail.com
              <br />
              Our Policy on “Do Not Track” Signals:
              <br />
              We honor Do Not Track signals and do not track, plant cookies, or use advertising when
              a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set
              in your web browser to inform websites that you do not want to be tracked.
              <br />
              You can enable or disable Do Not Track by visiting the Preferences or Settings page of
              your web browser.
              <br />
            </p>
          </Box>
          <Box className="YourDataProtectionRights">
            <h1 className={classes.heading}>
              11. Your Data Protection Rights under the Vietnam Consumer Privacy Act
            </h1>
            <p className={classes.textbody}>
              If you are a Vietnam resident, you are entitled to learn what data we collect about
              you, ask to delete your data and not to sell (share) it. To exercise your data
              protection rights, you can make certain requests and ask us:
              <br />
              <strong>
                0.1. What personal information we have about you. If you make this request, we will
                return to you:
              </strong>
              <br />
              0.0.1. The categories of personal information we have collected about you.
              <br />
              0.0.2. The categories of sources from which we collect your personal information.
              <br />
              0.0.3. The business or commercial purpose for collecting or selling your personal
              information.
              <br />
              0.0.4. The categories of third parties with whom we share personal information.
              <br />
              0.0.5. The specific pieces of personal information we have collected about you.
              <br />
              0.0.6. A list of categories of personal information that we have sold, along with the
              category of any other company we sold it to. If we have not sold your personal
              information, we will inform you of that fact.
              <br />
              0.0.7. A list of categories of personal information that we have disclosed for a
              business purpose, along with the category of any other company we shared it with.
              <br />
              Please note, you are entitled to ask us to provide you with this information up to two
              times in a rolling twelve-month period. When you make this request, the information
              provided may be limited to the personal information we collected about you in the
              previous 12 months.
              <br />
              <strong>
                0.2. To delete your personal information. If you make this request, we will delete
                the personal information we hold about you as of the date of your request from our
                records and direct any service providers to do the same. In some cases, deletion may
                be accomplished through de-identification of the information. If you choose to
                delete your personal information, you may not be able to use certain functions that
                require your personal information to operate.
              </strong>
              <br />
              <strong>
                0.3. To stop selling your personal information. We don’t sell or rent your personal
                information to any third parties for any purpose. We do not sell your personal
                information for monetary consideration. However, under some circumstances, a
                transfer of personal information to a third party, or within our family of
                companies, without monetary consideration may be considered a “sale” under Vietnam
                law. You are the only owner of your Personal Data and can request disclosure or
                deletion at any time.
              </strong>
              <br />
              If you submit a request to stop selling your personal information, we will stop making
              such transfers.
              <br />
              Please note, if you ask us to delete or stop selling your data, it may impact your
              experience with us, and you may not be able to participate in certain programs or
              membership services which require the usage of your personal information to function.
              But in no circumstances, we will discriminate against you for exercising your rights.
              <br />
              To exercise your Vietnam data protection rights described above, please send your
              request(s) by email: bus.tisoha@gmail.com.
              <br />
              Your data protection rights, described above, are covered by the VCPA, short for the
              Vietnam Consumer Privacy Act. To find out more, visit the official Vietnam Legislative
              Information website.
            </p>
          </Box>
          <Box className="Service Providers">
            <h1 className={classes.heading}>12. Service Providers</h1>
            <p className={classes.textbody}>
              We may employ third party companies and individuals to facilitate our Service{' '}
              <strong>( “Service Providers”)</strong>, provide Service on our behalf, perform
              Service-related services or assist us in analysing how our Service is used.
              <br />
              These third parties have access to your Personal Data only to perform these tasks on
              our behalf and are obligated not to disclose or use it for any other purpose.
            </p>
          </Box>
          <Box className="Analytics">
            <h1 className={classes.heading}>13. Analytics</h1>
            <p className={classes.textbody}>
              We may use third-party Service Providers to monitor and analyze the use of our
              Service.
            </p>
          </Box>
          <Box className="CI/CD tools">
            <h1 className={classes.heading}>14. CI/CD tools</h1>
            <p className={classes.textbody}>
              We may use third-party Service Providers to automate the development process of our
              Service.
            </p>
          </Box>
          <Box className="BehavioralRemarketing">
            <h1 className={classes.heading}>15. Behavioral Remarketing</h1>
            <p className={classes.textbody}>
              We may use remarketing services to advertise on third party websites to you after you
              visited our Service. We and our third-party vendors use cookies to inform, optimise
              and serve ads based on your past visits to our Service.
            </p>
          </Box>
          <Box className="Payments">
            <h1 className={classes.heading}>16. Payments</h1>
            <p className={classes.textbody}>
              We may provide paid products and/or services within Service. In that case, we use
              third-party services for payment processing (e.g. payment processors).
              <br />
              We will not store or collect your payment card details. That information is provided
              directly to our third-party payment processors whose use of your personal information
              is governed by their Privacy Policy. These payment processors adhere to the standards
              set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint
              effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
              requirements help ensure the secure handling of payment information.
            </p>
          </Box>
          <Box className="Links to Other Sites">
            <h1 className={classes.heading}>17. Links to Other Sites</h1>
            <p className={classes.textbody}>
              Our Service may contain links to other sites that are not operated by us. If you click
              a third party link, you will be directed to that third party’s site. We strongly
              advise you to review the Privacy Policy of every site you visit.
              <br />
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third party sites or services.
            </p>
          </Box>
          <Box className="Children’s Privacy">
            <h1 className={classes.heading}>18. Children’s Privacy</h1>
            <p className={classes.textbody}>
              Our Services are not intended for use by children under the age of 18{' '}
              <strong>(“Child” or “Children”)</strong>.<br />
              We do not knowingly collect personally identifiable information from Children under
              18. If you become aware that a Child has provided us with Personal Data, please
              contact us. If we become aware that we have collected Personal Data from Children
              without verification of parental consent, we take steps to remove that information
              from our servers.
            </p>
          </Box>
          <Box className="Changes to This Privacy Policy">
            <h1 className={classes.heading}>19. Changes to This Privacy Policy</h1>
            <p className={classes.textbody}>
              We may update our Privacy Policy from time to time. We will notify you of any changes
              by posting the new Privacy Policy on this page.
              <br />
              We will let you know via email and/or a prominent notice on our Service, prior to the
              change becoming effective and update “effective date” at the top of this Privacy
              Policy.
              <br />
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
              <br />
            </p>
          </Box>
          <Box className="Contact Us">
            <h1 className={classes.heading}>20. Contact Us</h1>
            <p className={classes.textbody}>
              If you have any questions about this Privacy Policy, please contact us by email:
              bus.tisoha@gmail.com
            </p>
          </Box>
        </Box>
      </SectionContainer>
    </Box>
  )
}
export default BodyPrivacyPolicy
