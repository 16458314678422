import React from "react";

const IconArrowButton = (props) => {
  return (
    <svg width={25} height={25} viewBox="0 0 52 52">
      <path
        d="M30 18l8 8m0 0H16m22 0l-8 8"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconArrowButton;
